/* eslint @typescript-eslint/no-var-requires: "off" */
import { useApi } from 'contexts/ApiContext'
import { CurrentSaleQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { BoxProps } from 'grommet'
import React, { FC, useCallback } from 'react'
import Dashboard from './component'

interface OwnProps extends BoxProps {
  currentSale: CurrentSaleQueryResult
  vehicle: VehicleSaleQueryResult
}

const DashboardContainer: FC<OwnProps> = ({ currentSale, vehicle }) => {
  const { sale } = useApi()

  const [mutateAwardedLot] = sale.useSallingState()

  const awardedLot = useCallback(() => {
    mutateAwardedLot({
      saleId: currentSale.id,
      vehicleId: vehicle.id,
    })
  }, [vehicle, currentSale, mutateAwardedLot])

  return (
    <Dashboard
      currentSale={currentSale}
      vehicle={vehicle}
      gridArea="dashboard"
      adjudicated={awardedLot}
    />
  )
}

export default DashboardContainer
