import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useIntl } from 'react-intl'
import { Heading, Box } from 'grommet'
import { Attraction, Stakeholder, Briefcase, Dashboard, Cart, Gallery } from 'grommet-icons'
import LinkBox from 'components/LinkBox'

const Home: FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()
  return (
    <div>
      <Heading textAlign="center">{formatMessage({ id: 'home.title' })}</Heading>
      <Box direction="row" gap="large" wrap justify="center">
        <LinkBox
          to="/auctioneer"
          data-cy="auctioneer-dashboard-btn"
          label={formatMessage({ id: 'home.menu.auctioneer' })}
          icon={<Stakeholder color="white" size="30px" />}
          background="red-300"
        />
        <LinkBox
          to="/operator"
          data-cy="operator-dashboard-btn"
          label={formatMessage({ id: 'home.menu.operator' })}
          icon={<Dashboard color="white" size="30px" />}
          background="purple-300"
        />
        <LinkBox
          data-cy="room-screen-btn"
          to="/room-screen"
          label={formatMessage({ id: 'home.menu.hall' })}
          icon={<Attraction color="white" size="30px" />}
          background="blue-300"
        />
        <LinkBox
          to="/client"
          label={formatMessage({ id: 'home.menu.customer' })}
          icon={<Cart color="white" size="30px" />}
          background="green-300"
        />
        <LinkBox
          data-cy="saler-screen-btn"
          to="/salesperson"
          label={formatMessage({ id: 'home.menu.salesperson' })}
          icon={<Briefcase color="white" size="30px" />}
          background="orange-300"
        />
        <LinkBox
          to="/slider"
          label={formatMessage({ id: 'home.menu.sliderimages' })}
          icon={<Gallery color="white" size="30px" />}
          background="indigo-300"
        />
      </Box>
    </div>
  )
}

export default Home
