import React, { FC } from 'react'
import { Text, TextProps } from 'grommet'

interface NumberProps extends TextProps {
  value: number
  locale?: string
  options?: {}
}

const Number: FC<NumberProps> = ({ value, locale = navigator.language, options, ...props }) => {
  const formatter = new Intl.NumberFormat(locale, { ...options })
  return <Text {...props}>{formatter.format(value)}</Text>
}

export default Number
