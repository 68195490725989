import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'

export interface HandlingFeesProps {
  currentSale: CurrentSaleQueryResult
  note?: boolean | undefined
}

const HandlingFees: FC<HandlingFeesProps> = ({ currentSale, note }) => {
  const { formatMessage } = useIntl()

  let handlingFees
  if (currentSale?.country === 'Portugal' || currentSale?.country === 'Belgique') {
    handlingFees = '100'
  } else {
    handlingFees = '150'
  }

  let id
  if (note) {
    id = 'client.footer.footnote.one'
  } else {
    id = 'client.auction.fees_extra'
  }

  return <>{formatMessage({ id: id }, { handlingFees: handlingFees })}</>
}

export default HandlingFees
