import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useIntl } from 'react-intl'
import { Box, Grommet, Text } from 'grommet'
import Spinner from 'components/Spinner'
import Cookies from 'universal-cookie'

const Logout: FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()
  const cookies = new Cookies()
  cookies.remove('userToken', { path: '/' })
  window.location.href = `${process.env.REACT_APP_API_URI}/logout/live`

  return (
    <Grommet>
      <Box justify="center" align="center" pad="small" background="white" round="small">
        <Text textAlign="center" size="50px">
          {formatMessage({ id: 'client.header.logout' })}
        </Text>
        <Spinner />
      </Box>
    </Grommet>
  )
}
export default Logout
