import React, { FC, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import { UserProvider } from '../contexts/UserContext'
import { AuthProvider } from '../contexts/AuthContext'
import { WebSocketProvider } from '../contexts/WebSocketContext'
import { ApiProvider } from '../contexts/ApiContext'
import { LoaderProvider } from '../contexts/LoaderContext'
import { Grommet } from 'grommet'
import AppRouter from './Router'
import translations, { Languages } from 'translations'
import theme from 'theme'
import { ReactQueryConfigProvider, useIsFetching } from 'react-query'

const App: FC = () => {
  const [isMutating, setMutating] = useState<boolean>(false)
  const [locale, setLocale] = useState<string>('fr')
  const isFetching = useIsFetching()

  const overrides = {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      onMutate() {
        setMutating(true)
      },
      onSettled() {
        setMutating(false)
      },
    },
  }

  return (
    <CookiesProvider>
      <IntlProvider locale={locale} messages={translations[locale as Languages]}>
        <ReactQueryConfigProvider config={overrides}>
          <WebSocketProvider locale={locale}>
            <ApiProvider>
              <LoaderProvider loading={isMutating || !!isFetching}>
                <AuthProvider setLocale={setLocale}>
                  <UserProvider>
                    <Grommet full theme={theme}>
                      <AppRouter></AppRouter>
                    </Grommet>
                  </UserProvider>
                </AuthProvider>
              </LoaderProvider>
            </ApiProvider>
          </WebSocketProvider>
        </ReactQueryConfigProvider>
      </IntlProvider>
    </CookiesProvider>
  )
}

export default App
