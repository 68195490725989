import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import './index.module.scss'
import { ReactQueryDevtools } from 'react-query-devtools'

ReactDOM.render(
  <React.StrictMode>
    <App />
    {JSON.parse(process.env.REACT_APP_DEBUG as string) && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </React.StrictMode>,
  document.getElementById('root')
)
