import React, { FC, useEffect, useState } from 'react'
import { Box, BoxProps, Grid, Button, Text, ThemeContext } from 'grommet'
import { useIntl } from 'react-intl'
import { Currency } from 'components/Number'
import { CurrentSaleQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import Timer, { Status } from 'services/timer'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  vehicle: VehicleSaleQueryResult
  adjudicated: Function
}

const Dashboard: FC<OwnProps & BoxProps> = ({
  adjudicated,
  vehicle,
  currentSale,
  children,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [tick, setTick] = useState<number | null>(null)

  const lastAuction = vehicle.lastAuctions?.[0]

  const isHT = vehicle.vat && (vehicle.category === 'Utilitaire' || vehicle.class === 'CTTE')
  const increment = currentSale.bidIncrement || vehicle.pricePitch

  const currentPrice = lastAuction
    ? lastAuction.amount + increment
    : vehicle.startingPrice + increment

  useEffect(() => {
    Timer.getInstance().on('tick', (ms: number) => {
      const timeLeft = Math.ceil(ms / 1000)
      setTick(timeLeft < 0 ? null : timeLeft)
    })
  }, [])
  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      <Box pad="xsmall" {...props}>
        <Grid
          gap="xsmall"
          columns={['1fr', '1fr']}
          rows={['100px', '100px', '100px']}
          areas={[
            { name: 'reservePrice', start: [0, 1], end: [0, 1] },
            { name: 'status', start: [1, 1], end: [1, 1] },
            { name: 'nextAuction', start: [0, 2], end: [0, 2] },
            { name: 'award', start: [1, 2], end: [1, 2] },
          ]}
        >
          <Box
            round="xxsmall"
            gridArea="reservePrice"
            align="center"
            justify="center"
            border={{ color: 'blue-200', size: 'small' }}
          >
            <Text color="blue-700">
              {formatMessage({ id: 'auctioneer.dashboard.reservePrice' })}
            </Text>
            <Currency
              weight="bold"
              color="blue-700"
              value={Number(vehicle.reservePrice)}
            ></Currency>
          </Box>
          <Box
            round="xxsmall"
            gridArea="nextAuction"
            align="center"
            justify="center"
            border={{ color: 'blue-200', size: 'small' }}
          >
            <Text color="blue-700">
              {formatMessage({ id: 'auctioneer.dashboard.nextAuction' })}
            </Text>
            <Text weight="bold" color="blue-700">
              {formatMessage({ id: 'auctioneer.dashboard.nextAuction.step' }, { step: increment })}
            </Text>
            <Box align="center" justify="center" direction="row" gap="small">
              {isHT && (
                <>
                  <Currency
                    value={parseInt((currentPrice / (vehicle.vatAmount / 100 + 1)).toString(), 10)}
                    weight="bold"
                    color="red-700"
                  ></Currency>
                  <Text color="blue-700"> (TFC HT) /</Text>
                </>
              )}
              <Currency
                value={Number(vehicle.highestAuction.amount) + Number(increment)}
                weight={isHT ? 'normal' : 'bold'}
                color={isHT ? 'blue-700' : 'red-700'}
              ></Currency>
              <Text color="blue-700">{isHT ? '(TFC TTC)' : ''}</Text>
            </Box>
          </Box>
          <Box
            gridArea="status"
            align="center"
            justify="center"
            round="xxsmall"
            border={{ color: 'red-200', size: 'small' }}
          >
            {
              <Text color="red-400" size="huge" data-cy="timer">
                {Timer.getInstance().status === Status.RUNNING && vehicle.auctionOpen
                  ? tick && tick
                  : formatMessage({ id: 'auctioneer.dashboard.status.close' })}
              </Text>
            }
          </Box>
          <Button
            disabled={!vehicle.lotOpen}
            primary
            gridArea="award"
            data-cy="adjudicated-btn"
            label={<Text size="xlarge">{formatMessage({ id: 'auctioneer.dashboard.award' })}</Text>}
            color="green-600"
            onClick={() => adjudicated()}
          />
        </Grid>
      </Box>
    </ThemeContext.Extend>
  )
}

export default Dashboard
