import React, { FC } from 'react'

const Chrono: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 72 79.6"
      version="1.1"
      viewBox="0 0 72 79.6"
      xmlSpace="preserve"
    >
      <path
        fill="currentColor"
        d="M60.6 17.4l.7-.7c1.7 1.5 3.5 2.2 4.2 1.5.8-.8-.1-2.8-1.8-4.5s-3.8-2.6-4.5-1.8c-.7.7 0 2.5 1.5 4.2l-.8.8c-6-5.4-13.8-8.7-22.4-9.1V6h-3v1.6C15.3 8.4 0 24.2 0 43.6c0 19.8 16.1 36 36 36s36-16.1 36-36c0-10.3-4.4-19.6-11.4-26.2zM55.7 21l-2.3 2.3.7.7 2.3-2.3c5.5 5.2 9.1 12.4 9.5 20.4h-6.3c-.7-11.9-10.3-21.4-22.2-22.2v-6.3c7 .4 13.3 3.1 18.3 7.4zM58 63.9L56.1 62l-.7.7 1.9 1.9c-5.1 5.2-12.1 8.5-19.8 8.9v-6h-3v6c-8.1-.4-15.4-4-20.5-9.6l1.7-1.7-.7-.7-1.6 1.6c-4.3-4.9-7-11.1-7.4-18h7.2v-3H6c.4-7.7 3.7-14.7 8.8-19.8l2.1 2.1.7-.7-2.1-2.1c5-4.7 11.6-7.6 18.9-8V34l1.5-.1c4.9 0 8.9 3.6 9.6 8.2v3h20.4c-.3 7.2-3.3 13.8-7.9 18.8zM35.8 6c4.7 0 8.6-1.3 8.6-3s-3.8-3-8.6-3c-4.7 0-8.6 1.3-8.6 3s3.9 3 8.6 3z"
      ></path>
    </svg>
  )
}

export default Chrono
