import React, { FC } from 'react'
import { Text, Box } from 'grommet'
import { useIntl } from 'react-intl'
import { CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'

export interface NotesProps {
  sale: CurrentSaleQueryResult
}

const Notes: FC<NotesProps> = ({ sale }) => {
  const { formatMessage } = useIntl()

  return (
    <Box margin={{ vertical: 'xsmall' }}>
      <Text size="xsmall">(1) {formatMessage({ id: 'client.footer.footnote.two' })}</Text>
      <Text size="xsmall">(2) {formatMessage({ id: 'client.footer.footnote.three' })}</Text>
    </Box>
  )
}

export default Notes
