import React, { FC } from 'react'
import { Box, Text } from 'grommet'
import { VehicleSaleQueryResult, CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { useIntl } from 'react-intl'
import styles from './Waiting.module.scss'
import Spinner from 'components/Spinner'
import Offer from '../Offer/component'

const Waiting: FC<{ vehicle: VehicleSaleQueryResult; currentSale: CurrentSaleQueryResult }> = ({
  vehicle,
  currentSale,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Box margin={{ vertical: '50px' }}>
      <Box align="center" margin={{ vertical: 'medium' }}>
        <Text weight="bold" textAlign="center" size="huge" className={styles.uppercase}>
          {formatMessage({ id: 'client.auction.opening_in' })}
        </Text>
        <Text size="xxlarge">{formatMessage({ id: 'client.auction.few_moments' })}</Text>
        <Spinner />
      </Box>
      <Offer vehicle={vehicle} currentSale={currentSale} isWaiting={true} />
    </Box>
  )
}

export default Waiting
