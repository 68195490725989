import { VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { Box, Button, Heading, Layer, Text, TextInput, ThemeContext } from 'grommet'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'

interface OwnProps {
  show: boolean
  setShow: Function
  vehicle: VehicleSaleQueryResult
  changePriceButton: Function
}

const ChangePrice: FC<OwnProps> = ({ setShow, show, vehicle, changePriceButton }) => {
  const { formatMessage } = useIntl()
  const [newPrice, setNewPrice] = useState<string>('')
  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      {show && (
        <Layer onEsc={() => setShow(false)} onClickOutside={() => setShow(false)}>
          <Box pad="medium" data-cy="popup-edit-price">
            <Box>
              <Heading level={4}>
                {formatMessage({ id: 'operator.autions.editPrice.modal.title' })}
              </Heading>
            </Box>
            <Box>
              <Text weight="bold" margin={{ vertical: 'medium' }}>
                {formatMessage({ id: 'operator.autions.editPrice.modal.label' })}
              </Text>
              <TextInput
                data-cy="input-edit-price"
                placeholder={vehicle.startingPrice}
                value={newPrice || vehicle.startingPrice}
                onChange={(event) => setNewPrice(event.target.value)}
              />
              <Box
                pad="xsmall"
                margin="small"
                align="center"
                justify="center"
                direction="row"
                gap="xsmall"
              >
                <Button
                  primary
                  color="gray-500"
                  gap="none"
                  label="Fermer"
                  onClick={() => {
                    setShow(false)
                  }}
                />
                <Button
                  primary
                  gridArea="award"
                  data-cy="submit-edit-price"
                  label="Enregistrer les changements"
                  color="green-600"
                  onClick={() => {
                    changePriceButton({ startPrice: newPrice })
                    setNewPrice('')
                    setShow(false)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </ThemeContext.Extend>
  )
}

export default ChangePrice
