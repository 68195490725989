import { Redirect } from '@reach/router'
import Restricted from 'app/Restricted'
import { useUser } from 'contexts/UserContext'
import { Permissions } from 'contexts/AuthContext/types'
import React, { FC } from 'react'

export enum ROLES {
  isAuctioneer = 'isAuctioneer',
  isDemoUser = 'isDemoUser',
  isForeigner = 'isForeigner',
  isOperator = 'isOperator',
  isSalesperson = 'isSalesperson',
}

interface WithRolesProps {
  roles: Array<ROLES>
  fallbackUrl?: string
}

function withRoles<WrappedProps = {}>({ roles, fallbackUrl }: WithRolesProps) {
  return (Component: React.ComponentType<WrappedProps>): FC<WrappedProps> => {
    const WithRoles: FC<WrappedProps> = (props) => {
      const user = useUser()
      const isAllowedToContinue = roles.some((role: keyof Permissions) => user[role])

      if (isAllowedToContinue) {
        return <Component {...(props as WrappedProps)} />
      }
      if (!fallbackUrl) {
        return <Restricted />
      }

      return <Redirect noThrow to={fallbackUrl} />
    }

    return WithRoles
  }
}

export default withRoles
