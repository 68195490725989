export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'WYPRZEDAŻ NA ŻYWO',
  'hall.lotNumber.label': 'Nr {number}',
  'hall.newPrice': 'Cena nowego pojazdu* : {price}',
  'hall.taxes.label': 'VAT',
  'hall.taxes.yes': 'TAK',
  'hall.taxes.no': 'NIE',
  'client.attr.auction.loading': 'Wczytywanie...',
  'client.attr.vehicle.photo_next': 'Następne zdjęcie',
  'client.attr.vehicle.photo_previous': 'Poprzednie zdjęcie',
  'client.auction.bid': 'Licytuj',
  'client.auction.fees_extra': 'Dodatkowe opłaty administracyjne: {handlingFees} Euro',
  'client.auction.fees_included': 'Koszty sprzedaży wliczone w cenę',
  'client.auction.few_moments': 'za chwilę',
  'client.auction.losing': 'Nie możesz licytować',
  'client.auction.lost_text': 'Nie wygrałeś licytacji.',
  'client.auction.lot_closed': 'Aukcja zakończona',
  'client.auction.next_auction': 'Następna licytacja',
  'client.auction.opening_in': 'Otwarcie aukcji',
  'client.auction.register_to_bid': 'Zapisz się, aby licytować',
  'client.auction.same_amount': 'Licytacja z tą samą kwotą',
  'client.auction.seconds': 'Sekunda(y)',
  'client.auction.sold': 'Sprzedane',
  'client.auction.starting_price': 'Cena wywoławcza',
  'client.auction.vat_excluded': 'Bez VAT',
  'client.auction.winning': 'Możesz licytować',
  'client.auction.won_text1': 'Brawo! wygrałeś licytację',
  'client.auction.won_text2': 'Skontaktujemy się z Tobą.',
  'client.auction.you': 'Ty',
  'client.auction.salle': 'Sala',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Powrót do katalogu',
  'client.footer.footnote.one': 'Dodatkowe opłaty administracyjne: {handlingFees} Euro brutto',
  'client.footer.footnote.three': 'Referencja Codex w dniu odbioru lub oryginalna faktura',
  'client.footer.footnote.two':
    'Wycena prorata temporis, cena rynkowa lub wycena szacunkowa, podana jako wskazówka, za którą VPauto nie ponosi odpowiedzialności',
  'client.footer.vehicle_link': 'Szczegółowa karta aukcji',
  'client.header.live_from': 'Na żywo z',
  'client.header.logout': 'Wyloguj się',
  'client.popup.body.first': 'Wchodzisz do sali aukcyjnej jako licytujący.',
  'client.popup.body.second':
    'Kliknięcie przycisku Licytuj oznacza wzięcie udziału w aukcji i zobowiązuje Cię.',
  'client.popup.body.third':
    'Licytacje zawierają wszystkie koszty sprzedaży (opłaty administracyjne będą doliczone dodatkowo).',
  'client.popup.confirm': 'Zrozumiałe(a)m',
  'client.popup.header': 'Uwaga',
  'client.vehicle.kilometers': 'Km',
  'client.vehicle.lot_number': 'Nr',
  'client.vehicle.new_price': 'Cena nowego pojazdu',
  'client.vehicle.observations': 'Uwagi',
  'client.vehicle.options': 'Wyposażenie',
  'client.vehicle.quote': 'Wycena',
  'client.vehicle.vat': 'VAT',
  'client.vehicle.localisation': 'Lokalizacja',
  'client.video.live_sale': 'Zobacz wyprzedaż na żywo',
  'loading.app': 'Proszę czekać, logowanie w toku...',
  'loading.sale': 'Wczytywanie sprzedaży w toku...',
  'loading.vehicle': 'Wczytywanie pojazdu w toku...',
  'next_sale.content.links.catalog': 'Katalog sprzedaży',
  'next_sale.content.links.sale_hours': 'Godziny sprzedaży',
  'next_sale.content.paused': '<bold>Sprzedaż</bold> wstrzymana',
  'next_sale.content.restored_in': '<bold>Wznowienie</bold> za',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {godzina}
    =1 {godzina}
    other {godzinay}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minuta}
    =1 {minuta}
    other {minutay}
  }`,
  'next_sale.footer.access': 'Wybierz <bold>rodzaj dostępu</bold>',
  'next_sale.footer.guide': '<bold>Zasady funkcjonowania</bold> aukcji Na Żywo',
  'next_sale.footer.main_site': '<bold>Powrót</bold> do VPauto',
  'next_sale.footer.planning': '<bold>Harmonogram</bold> następnych sprzedaży Na Żywo',
  'next_sale.footer.sound': '<bold>Słuchaj</bold> sprzedaży',
  'next_sale.footer.sound_instructions': 'Aktualnie ta funkcja jest niedostępna.',
  'next_sale.header.live_from': '@:client.header.live_from',
}
