import React, { FC } from 'react'
import { Text, Box } from 'grommet'
import { useIntl } from 'react-intl'

const VideoStream: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Box
      border={{ size: 'small', color: 'gray-400' }}
      align="center"
      round="xsmall"
      pad="small"
      margin={{ bottom: 'small' }}
    >
      <Text size="small" margin={{ bottom: 'small' }}>
        {formatMessage({ id: 'client.video.live_sale' })}
      </Text>
      <div>
        <iframe
          width="200"
          height="113"
          src="//antmedia1.francecentral.cloudapp.azure.com:/LiveApp/play.html?name=939397985026046786593448"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    </Box>
  )
}

export default VideoStream
