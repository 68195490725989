import { Box, BoxProps, Text } from 'grommet'
import React, { FC } from 'react'
import styles from './FigureBox.module.scss'

interface FigureBoxProps extends BoxProps {
  label: string
  figure: number
}

const FigureBox: FC<FigureBoxProps> = ({ label, figure, ...props }) => {
  return (
    <Box
      border={{ color: 'black', size: 'xsmall' }}
      pad="medium"
      className={styles.radius}
      width="small"
    >
      <Text alignSelf="center" size="xgiant" color="red-600" weight="bold">
        {figure}
      </Text>
      <Text alignSelf="center" color="blue-800">
        {label}
      </Text>
    </Box>
  )
}

export default FigureBox
