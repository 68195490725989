import React, { FC } from 'react'
import { Box, BoxProps, ThemeContext, Text, TextProps } from 'grommet'
import Toolbox from './Toolbox'
import styles from './Lot.module.scss'
import {
  VehicleSaleQueryResult,
  VehicleOrderSaleQueryResult,
  CurrentSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import { useLocation } from '@reach/router'
import { extendedOperatorTheme } from 'app/Operator/component'

const Watchme: FC<TextProps> = ({ children, ...props }) => (
  <Text {...props} weight="bold">
    {children}
  </Text>
)

const NC = 'NC'

interface OwnProps extends BoxProps {
  vehicle: VehicleSaleQueryResult
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
  currentSale: CurrentSaleQueryResult
}

const Lot: FC<OwnProps> = ({ vehicle, vehiclesOrderSale, currentSale, children, ...props }) => {
  const location = useLocation()
  const isAuctionner = location.pathname.includes('auctioneer')

  const isAdjudged = vehicle.salingState === 'Adjugé' || vehicle.salingState === 'Adjug&eacute;'
  const decodeStateAdjuge = vehicle.salingState.replace('&eacute;', 'é')

  const isJudiciaire = vehicle.tarificationType === 'Judiciaire'

  return (
    <ThemeContext.Extend value={extendedOperatorTheme}>
      <Box background="indigo-600" {...props} style={{ position: 'relative' }}>
        <Box overflow="auto" pad="xsmall" margin={{ bottom: '70px' }} height="100%">
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Statut</td>
                <td>
                  <Box
                    background={isAdjudged ? 'green-500' : 'gray-500'}
                    align="center"
                    direction="row"
                    justify="center"
                    pad={{ vertical: 'xxsmall' }}
                    round="xxsmall"
                  >
                    <Text color="white" alignSelf="center" weight="bold" data-cy="status-lot">
                      {isAdjudged ? decodeStateAdjuge : vehicle.salingState}
                    </Text>
                  </Box>
                </td>
              </tr>
              <tr>
                <td>Dossier</td>
                <td colSpan={3}>{vehicle.etincelleId ? vehicle.etincelleId : NC}</td>
              </tr>
              <tr>
                <td>Vendeur</td>
                <td colSpan={3}>
                  <Watchme>{vehicle.client ? vehicle.client : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Immat</td>
                <td colSpan={3}>
                  <Watchme>{vehicle.immat ? vehicle.immat : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Tarification</td>
                <td colSpan={3}>
                  <Box
                    background={isJudiciaire ? 'red-500' : ''}
                    align="center"
                    direction="row"
                    justify="start"
                    pad={{ vertical: 'xxsmall' }}
                    round="xxsmall"
                    width="100%"
                  >
                    <Text color="white" alignSelf="center" weight="bold" data-cy="status-lot">
                      {vehicle.tarification}
                    </Text>
                  </Box>
                </td>
              </tr>
            </tbody>
            <tbody className="bottom-line">
              <tr>
                <td>Marque</td>
                <td>
                  <Watchme size="large">{vehicle.maker ? vehicle.maker : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Type</td>
                <td colSpan={3}>
                  <Watchme size="large">{vehicle.model ? vehicle.model : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>MEC</td>
                <td>
                  <Watchme>{vehicle.registrationDate ? vehicle.registrationDate : NC}</Watchme>
                </td>
                <td>KM Réel</td>
                <td>
                  <Watchme>{vehicle.kilometers ? vehicle.kilometers + ' KM' : NC}</Watchme>
                </td>
              </tr>
              {!isAuctionner && (
                <tr>
                  <td>Puissance</td>
                  <td>
                    <Watchme>{vehicle.taxHorsePower ? vehicle.taxHorsePower + ' CV' : NC}</Watchme>
                  </td>
                  <td>Carrosserie</td>
                  <td>
                    <Watchme>{vehicle.bodyType ? vehicle.bodyType : NC}</Watchme>
                  </td>
                </tr>
              )}
              <tr>
                <td>Couleur</td>
                <td>
                  <Watchme>{vehicle.color ? vehicle.color : NC}</Watchme>
                </td>
                {!isAuctionner && (
                  <>
                    <td>FRE</td>
                    <td>
                      <Watchme>{vehicle.fre ? `${vehicle.fre}  €` : NC}</Watchme>
                    </td>{' '}
                  </>
                )}
              </tr>
              {!isAuctionner && (
                <tr>
                  <td>CE</td>
                  <td>
                    <Watchme>{vehicle.ce ? vehicle.ce : NC}</Watchme>
                  </td>
                  <td>SE</td>
                  <td>
                    <Watchme>{vehicle.se ? vehicle.se : NC}</Watchme>
                  </td>
                </tr>
              )}
            </tbody>
            <tbody className=" bottom-line">
              <tr>
                <td>Prix Neuf</td>
                <td>
                  <Watchme>{vehicle.unusedPrice ? `${vehicle.unusedPrice} €` : NC}</Watchme>
                </td>
                <td>Garantie</td>
                <td>
                  <Watchme>{vehicle.guarantee ? vehicle.guarantee : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Cote datée</td>
                <td>
                  <Watchme>{vehicle.argusDatedPrice ? `${vehicle.argusDatedPrice} €` : NC}</Watchme>
                </td>
                <td>Cote km</td>
                <td>
                  <Watchme>{vehicle.kmQuote ? `${vehicle.kmQuote} €` : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Estimation</td>
                <td>
                  <Watchme>{vehicle.estimationPrice ? `${vehicle.estimationPrice} €` : NC}</Watchme>
                </td>
                <td>TVA</td>
                <td>
                  <Watchme>{vehicle.vat ? 'Oui' : 'Non'}</Watchme>
                </td>
              </tr>
              <tr>
                <td>NB R</td>
                <td>
                  <Watchme>{isNaN(vehicle.nbr) ? NC : vehicle.nbr}</Watchme>
                </td>
                <td>NB J</td>
                <td>
                  <Watchme>{vehicle.nbj ? vehicle.nbj : NC}</Watchme>
                </td>
              </tr>
            </tbody>
            <tbody className="bottom-line">
              <tr>
                <td>Prix de départ</td>
                <td data-cy="starting-price">
                  <Watchme>{vehicle.startingPrice} €</Watchme>
                </td>
                <td>Prix de réserve</td>
                <td data-cy="reserve-price">
                  <Watchme>{vehicle.reservePrice} €</Watchme>
                </td>
              </tr>
              {!isAuctionner && (
                <tr>
                  <td>Prix de départ HF</td>

                  <td>
                    <Watchme>{vehicle.startingPriceHf} €</Watchme>
                  </td>
                  <td>Prix de réserve HF</td>
                  <td>
                    <Watchme>{vehicle.reservePriceHf} €</Watchme>
                  </td>
                </tr>
              )}
            </tbody>
            <tbody className="bottom-line">
              <tr>
                <td>Obs V</td>
                <td colSpan={3}>
                  <Watchme>{vehicle.obsV ? vehicle.obsV : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Etat</td>
                <td colSpan={3}>
                  <Watchme>{vehicle.stateDetails ? vehicle.stateDetails : NC}</Watchme>
                </td>
              </tr>
              <tr>
                <td>Obs A</td>
                <td colSpan={3}>
                  <Watchme>
                    {vehicle.obsA.map((el: string, i) => (
                      <div key={i}>
                        <div>{el}</div>
                      </div>
                    ))}
                  </Watchme>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Toolbox
          currentSale={currentSale}
          vehiclesOrderSale={vehiclesOrderSale}
          vehicle={vehicle}
        />
      </Box>
    </ThemeContext.Extend>
  )
}

export default Lot
