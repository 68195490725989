import React, { FC, useCallback } from 'react'
import { CurrentSaleQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { useApi } from 'contexts/ApiContext'
import CloseAuction from './component'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  vehicle: VehicleSaleQueryResult
  show: boolean
  setShow: Function
}

const CloseAuctionContainer: FC<OwnProps> = ({ currentSale, vehicle, show, setShow }) => {
  const { sale } = useApi()

  const [mutateCloseAuction] = sale.useCloseAuction()

  const closeAuction = useCallback(() => {
    mutateCloseAuction({
      saleId: currentSale.id,
      vehicleId: vehicle.id,
    })
  }, [currentSale, vehicle, mutateCloseAuction])

  return <CloseAuction show={show} setShow={setShow} closeAuction={closeAuction} />
}
export default CloseAuctionContainer
