import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useApi } from 'contexts/ApiContext'
import Loading from 'app/Loading'
import Hall from './component'
import useLastOpenedVehicle from 'hooks/useLastOpenedVehicule'

const HallContainer: FC<RouteComponentProps> = () => {
  const { sale } = useApi()

  const { data: currentSale } = sale.useCurrentSale()

  const lastOpenedVehicle = useLastOpenedVehicle(currentSale)

  const { data: vehicle } = sale.useVehiclesSale(
    currentSale?.id,
    currentSale?.openedVehicle || lastOpenedVehicle,
    {
      enabled: currentSale,
    }
  )

  const { data: auctions } = sale.useAuctions(currentSale?.id, vehicle?.id, {
    enabled: vehicle,
  })

  if (!currentSale || !vehicle || !auctions) {
    return <Loading />
  }

  return <Hall currentSale={currentSale} vehicle={vehicle} auctions={auctions} />
}
export default HallContainer
