import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

interface Error {
  status: string
  message: string
}

export const callApi = async <T>(
  url: string,
  config: AxiosRequestConfig
): Promise<AxiosResponse<T & Error>> => {
  const response: AxiosResponse<T & Error> = await axios.request<T & Error>({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URI}/${url}`,
    ...config,
    headers: {
      accept: 'application/json, text/plain, */*',
      ...config.headers,
    },
  })

  if (response.data.status === 'error') {
    throw new Error(response.data.message)
  }

  return response
}

function getCookie(name: string): string | undefined {
  const pair = document.cookie.split('; ').find((x) => x.startsWith(name + '='))
  if (pair) return pair.split('=')[1]
}

export const callAuthenticatedApi = async <T>(
  url: string,
  config: AxiosRequestConfig | undefined = {}
): Promise<AxiosResponse<T>> => {
  const userToken = getCookie('userToken') as string

  return await callApi<T>(url, {
    ...config,
    headers: {
      ...(config?.headers ?? {}),
    },
    params: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      access_token: userToken?.substring(0, userToken?.length - 6),
      ...(config?.params ?? {}),
    },
  })
}
