import React, { FC } from 'react'
import { Main } from 'grommet'
import ResponsiveGrid from 'components/ResponsiveGrid'
import Lot from '../components/Lot'
import AuctionsTable from '../components/AuctionsTable'
import Dashboard from './Dashboard'
import {
  AuctionQueryResult,
  CurrentSaleQueryResult,
  VehicleOrderSaleQueryResult,
  VehicleSaleQueryResult,
} from 'contexts/ApiContext/services/sale'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[] | []
}

const Operator: FC<OwnProps> = ({ vehicle, vehiclesOrderSale, currentSale, auctions }) => {
  return (
    <Main overflow="auto">
      <ResponsiveGrid
        fill
        columns={{
          small: ['100%'],
          medium: ['minmax(min-content, 33%)', '1fr'],
        }}
        rows={{
          small: ['min-content', 'min-content', 'min-content'],
          medium: ['1fr', 'min-content'],
        }}
        areas={{
          xsmall: [
            { name: 'lot', start: [0, 0], end: [0, 0] },
            { name: 'auctions', start: [0, 1], end: [0, 1] },
            { name: 'dashboard', start: [0, 2], end: [0, 2] },
          ],
          medium: [
            { name: 'lot', start: [0, 0], end: [0, 1] },
            { name: 'auctions', start: [1, 0], end: [1, 0] },
            { name: 'dashboard', start: [1, 1], end: [1, 1] },
          ],
        }}
      >
        <Lot
          currentSale={currentSale}
          vehicle={vehicle}
          vehiclesOrderSale={vehiclesOrderSale}
          gridArea="lot"
        ></Lot>
        <AuctionsTable
          gridArea="auctions"
          auctions={auctions}
          vehicle={vehicle}
          currentSale={currentSale}
        />
        <Dashboard
          gridArea="dashboard"
          vehicle={vehicle}
          currentSale={currentSale}
          auctions={auctions}
          vehiclesOrderSale={vehiclesOrderSale}
        />
      </ResponsiveGrid>
    </Main>
  )
}

export default Operator

export const extendedOperatorTheme = {
  text: {
    xsmall: {
      size: `0.5rem`,
      height: '1.1',
    },
    small: {
      size: `0.6rem`,
      height: '1.1',
    },
    medium: {
      size: `0.8rem`,
      height: '1.1',
    },
    large: {
      size: `1rem`,
      height: '1.1',
    },
    xlarge: {
      size: `1.2rem`,
      height: '1.1',
    },
    xxlarge: {
      size: `1.4rem`,
      height: '1.1',
    },
    huge: {
      size: `2rem`,
      height: '1.1',
    },
    giant: {
      size: `3rem`,
      height: '1.1',
    },
    xgiant: {
      size: `4rem`,
      height: '1.1',
    },
  },
}
