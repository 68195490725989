import { QueryResult, useQuery } from 'react-query'
import { callAuthenticatedApi } from './utils'

export interface User {
  country: string
  name: string
  email: string
  id: string
  isPro: string
  logged: boolean
  loginAt: string
  nbBids: string
}

export interface UsersLoggedListQueryResult {
  count: number
  users: User[]
}

export interface UserInfoResult {
  id: string
  liveLoggedInAt: string
  liveLoggedOutAt: string | null
  serverTimestamp: string
}

export const useUsersLoggedList = (): QueryResult<UsersLoggedListQueryResult> => {
  return useQuery<UsersLoggedListQueryResult, string>('usersLoggedList', async () => {
    const data = await getUsersList()
    return data
  })
}

export const getUserInfo = async (userId: number | string): Promise<User> => {
  const { data } = await callAuthenticatedApi<User>(`live/api/users/${userId}/logged/info`, {
    method: 'GET',
    params: {
      hash: new Date().getTime(),
    },
  })

  return data
}

export const getUsersList = async (): Promise<UsersLoggedListQueryResult> => {
  const { data } = await callAuthenticatedApi<UsersLoggedListQueryResult>(
    `live/api/users/logged/list`,
    {
      method: 'GET',
      params: {
        hash: new Date().getTime(),
      },
    }
  )
  return data
}
