import React, { FC, useCallback } from 'react'
import Toolbox from './component'
import {
  VehicleSaleQueryResult,
  VehicleOrderSaleQueryResult,
  CurrentSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import { useApi } from 'contexts/ApiContext'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
  currentSale: CurrentSaleQueryResult
}

const ToolBoxContainer: FC<OwnProps> = ({ vehicle, vehiclesOrderSale, currentSale }) => {
  const currentVehicleOrder: VehicleOrderSaleQueryResult = vehiclesOrderSale.find(
    ({ id }) => id === vehicle.id
  ) as VehicleOrderSaleQueryResult

  const { sale } = useApi()

  const currentIndex = vehiclesOrderSale.indexOf(currentVehicleOrder)

  const [mutateChangeVehicleSale] = sale.useChangeVehicleSale()

  const mutateChangeVehicle = useCallback(
    (vehicleId) => {
      mutateChangeVehicleSale({
        saleId: currentSale.id,
        vehicleId,
      })
    },
    [currentSale.id, mutateChangeVehicleSale]
  )

  const nextVehicle = useCallback(() => {
    const vehicleSale = vehiclesOrderSale[currentIndex + 1]
      ? vehiclesOrderSale[currentIndex + 1]
      : vehiclesOrderSale[0]

    mutateChangeVehicle(vehicleSale.id)
  }, [vehiclesOrderSale, mutateChangeVehicle, currentIndex])

  const prevVehicle = useCallback(() => {
    const vehicleSale = vehiclesOrderSale[currentIndex - 1]
      ? vehiclesOrderSale[currentIndex - 1]
      : vehiclesOrderSale[vehiclesOrderSale.length - 1]

    mutateChangeVehicle(vehicleSale.id)
  }, [vehiclesOrderSale, mutateChangeVehicle, currentIndex])

  const firstVehicle = useCallback(() => {
    const vehicleSale = vehiclesOrderSale[0]

    mutateChangeVehicle(vehicleSale.id)
  }, [vehiclesOrderSale, mutateChangeVehicle])

  const lastVehicle = useCallback(() => {
    const vehicleSale = vehiclesOrderSale[vehiclesOrderSale.length - 1]

    mutateChangeVehicle(vehicleSale.id)
  }, [vehiclesOrderSale, mutateChangeVehicle])

  const changeVehicle = useCallback(
    (vehicleId: string) => {
      mutateChangeVehicle(vehicleId)
    },
    [mutateChangeVehicle]
  )

  return (
    <Toolbox
      vehicle={vehicle}
      nextVehicle={nextVehicle}
      prevVehicle={prevVehicle}
      firstVehicle={firstVehicle}
      lastVehicle={lastVehicle}
      changeVehicle={changeVehicle}
      currentSale={currentSale}
      vehiclesOrderSale={vehiclesOrderSale}
    />
  )
}
export default ToolBoxContainer
