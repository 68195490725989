import React, { FC } from 'react'
import { Box, Button, Layer, ThemeContext, Text } from 'grommet'
import { useIntl } from 'react-intl'

interface OwnProps {
  closeLotButton: Function
  awardedButton: Function
  show: boolean
  setShow: Function
}

const CloseLot: FC<OwnProps> = ({ closeLotButton, awardedButton, setShow, show }) => {
  const { formatMessage } = useIntl()

  const validModal = (event: { charCode: number }): void => {
    if (event.charCode === 13) {
      awardedButton()
      setShow(false)
    }
  }

  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
      data-cy="pop-up-close-lot"
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          onKeyPress={validModal}
        >
          <Box background="white" round="xsmall" pad="xsmall" margin="small" gap="xsmall" width="">
            <Box pad="xsmall">
              <Text weight="bold" size="xlarge" alignSelf="center" color="black">
                {formatMessage({ id: 'lot.toolbox.modal.closeLot.title' })}
              </Text>
            </Box>
            <Box pad="xsmall">
              <Text weight="bold" size="xlarge" alignSelf="center" color="black">
                {formatMessage({ id: 'lot.toolbox.modal.closeLot.subtitle' })}
              </Text>
            </Box>
            <Box
              pad="xsmall"
              margin="small"
              align="center"
              justify="center"
              direction="row"
              gap="xsmall"
            >
              <Button
                primary
                color="blue-500"
                gap="none"
                label={formatMessage({ id: 'lot.toolbox.modal.closeLot.no' })}
                onClick={() => {
                  closeLotButton()
                  setShow(false)
                }}
              />
              <Button
                gap="none"
                primary
                color="red-600"
                data-cy="close-yes-btn"
                label={formatMessage({ id: 'lot.toolbox.modal.closeLot.yes' })}
                active
                type="submit"
                onClick={() => {
                  awardedButton()
                  setShow(false)
                }}
              ></Button>
            </Box>
          </Box>
        </Layer>
      )}
    </ThemeContext.Extend>
  )
}

export default CloseLot
