import { useApi } from 'contexts/ApiContext'
import {
  AuctionQueryResult,
  CurrentSaleQueryResult,
  VehicleOrderSaleQueryResult,
  VehicleSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import { BoxProps } from 'grommet'
import React, { FC, useCallback, useState } from 'react'
import Dashboard from './component'

interface OwnProps extends BoxProps {
  currentSale: CurrentSaleQueryResult
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[] | []
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
}

const DashboardContainer: FC<OwnProps> = ({
  currentSale,
  vehicle,
  auctions,
  vehiclesOrderSale,
}) => {
  const [error, setError] = useState<string>('')

  const currentVehicleOrder: VehicleOrderSaleQueryResult = vehiclesOrderSale.find(
    ({ id }) => id === vehicle.id
  ) as VehicleOrderSaleQueryResult

  const { sale } = useApi()

  const handleError = useCallback((err: any) => {
    const message = err.message
    if (message) {
      setError(message)
      setTimeout(() => setError(''), 5000)
    }
  }, [])

  const currentIndex = vehiclesOrderSale.indexOf(currentVehicleOrder)

  const [mutateChangeVehicleSale] = sale.useChangeVehicleSale()

  const nextVehicle = useCallback(() => {
    const vehicleSale = vehiclesOrderSale[currentIndex + 1]
      ? vehiclesOrderSale[currentIndex + 1]
      : vehiclesOrderSale[0]

    mutateChangeVehicleSale({
      saleId: currentSale.id,
      vehicleId: vehicleSale.id,
    })
  }, [vehiclesOrderSale, mutateChangeVehicleSale, currentIndex, currentSale.id])

  const [mutateOpenSale] = sale.useOpenLot()

  const openLot = useCallback(() => {
    mutateOpenSale(
      {
        saleId: currentSale.id,
        vehicleId: vehicle.id,
      },
      {
        onError(err) {
          handleError(err)
        },
      }
    )
  }, [currentSale, vehicle, mutateOpenSale])

  const [mutateCloseSale] = sale.useCloseLot()

  const closeLot = useCallback(() => {
    mutateCloseSale(
      {
        saleId: currentSale.id,
        vehicleId: vehicle.id,
      },
      {
        onSuccess() {
          nextVehicle()
        },
      }
    )
  }, [vehicle, currentSale, nextVehicle, mutateCloseSale])

  const [mutateChangePrice] = sale.useChangePrice()

  const changePrice = useCallback(
    ({ startPrice }) => {
      mutateChangePrice({
        saleId: currentSale.id,
        vehicleId: vehicle.id,
        startPrice: startPrice,
      })
    },
    [vehicle, currentSale, nextVehicle, mutateChangePrice]
  )

  const [mutateAwardedLot] = sale.useSallingState()

  const awardedLot = useCallback(() => {
    mutateAwardedLot(
      {
        saleId: currentSale.id,
        vehicleId: vehicle.id,
      },
      {
        onSuccess() {
          closeLot()
        },
      }
    )
  }, [vehicle, currentSale, closeLot, mutateAwardedLot])

  const [mutateOpenAuction] = sale.useOpenAuction()

  const openAuction = useCallback(
    (cb = () => undefined) => {
      mutateOpenAuction(
        {
          saleId: currentSale.id,
          vehicleId: vehicle.id,
        },
        {
          onSuccess() {
            cb()
          },
        }
      )
    },
    [vehicle, currentSale, mutateOpenAuction]
  )

  const [mutateSendBid] = sale.useSendBid()

  const sendBid = useCallback(
    (origin) => {
      const mutate = (): void => {
        mutateSendBid({
          saleId: currentSale.id,
          vehicleId: vehicle.id,
          data: {
            origin: origin,
            roomOA: 1,
            timeStamp: new Date().getTime(),
          },
        })
      }

      if (vehicle.auctionOpen) {
        mutate()
      } else {
        openAuction(mutate)
      }
    },
    [openAuction, vehicle, currentSale, mutateSendBid]
  )

  const [mutatePauseLot] = sale.usePauseLot()

  const pauseLot = useCallback(
    ({ hours, minutes }) => {
      mutatePauseLot({
        saleId: currentSale.id,
        hours: hours,
        minutes: minutes,
      })
    },
    [currentSale, mutatePauseLot]
  )

  const [mutateChangeDefaultPitch] = sale.useChangeDefaultPitch()

  const changeDefaultPitch = useCallback((defaultPitch) => {
    mutateChangeDefaultPitch({
      saleId: currentSale.id,
      vehicleId: vehicle.id,
      defaultPitch: defaultPitch,
    })
  }, [])

  const [mutateChangeBidPitch] = sale.useChangeBidPitch()

  const changeBidPitch = useCallback((bidPitch) => {
    mutateChangeBidPitch({
      saleId: currentSale.id,
      vehicleId: vehicle.id,
      bidPitch: bidPitch,
    })
  }, [])

  const [mutateRestoreLot] = sale.useRestoreLot()

  const restoreLot = useCallback(() => {
    mutateRestoreLot({
      saleId: currentSale.id,
    })
  }, [currentSale, mutateRestoreLot])

  const [mutateChangeStartTimer] = sale.useSetStartingDuration()

  const changeStartTimer = useCallback((second) => {
    mutateChangeStartTimer({
      saleId: currentSale.id,
      vehicleId: vehicle.id,
      second: second,
    })
  }, [])

  const [mutateChangeAuctionDuration] = sale.useSetAuctionDuration()

  const changeAuctionDuration = useCallback((second) => {
    mutateChangeAuctionDuration({
      saleId: currentSale.id,
      vehicleId: vehicle.id,
      second: second,
    })
  }, [])

  return (
    <Dashboard
      error={error}
      vehicle={vehicle}
      currentSale={currentSale}
      auctions={auctions}
      openLot={openLot}
      closeLot={closeLot}
      openAuction={openAuction}
      sendBid={sendBid}
      pauseLot={pauseLot}
      restoreLot={restoreLot}
      salePaused={currentSale.paused}
      awardedButton={awardedLot}
      changePrice={changePrice}
      changeDefaultPitch={changeDefaultPitch}
      changeStartTimer={changeStartTimer}
      changeAuctionDuration={changeAuctionDuration}
      changeBidPitch={changeBidPitch}
    />
  )
}

export default DashboardContainer
