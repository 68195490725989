import React, { FC } from 'react'
import { Box, Button, Layer, ThemeContext, Text } from 'grommet'
import { useIntl } from 'react-intl'

interface OwnProps {
  closeAuction: Function
  show: boolean
  setShow: Function
}

const CloseAuction: FC<OwnProps> = ({ closeAuction, setShow, show }) => {
  const { formatMessage } = useIntl()

  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      {show && (
        <Layer onEsc={() => setShow(false)} onClickOutside={() => setShow(false)}>
          <Box background="white" round="xsmall" pad="xsmall" margin="small" gap="xsmall" width="">
            <Box pad="xsmall">
              <Text weight="bold" size="xlarge" alignSelf="center" color="black">
                {formatMessage({ id: 'lot.toolbox.modal.closeAuction.title' })}
              </Text>
            </Box>
            <Box
              pad="xsmall"
              margin="small"
              align="center"
              justify="center"
              direction="row"
              gap="xsmall"
            >
              <Button
                data-cy="modal-delete-auction-btn-yes"
                gap="none"
                primary
                color="red-600"
                label={formatMessage({ id: 'lot.toolbox.modal.closeAuction.yes' })}
                onClick={() => {
                  closeAuction()
                  setShow(false)
                }}
              ></Button>
              <Button
                primary
                color="blue-500"
                gap="none"
                label={formatMessage({ id: 'lot.toolbox.modal.closeAuction.no' })}
                onClick={() => {
                  setShow(false)
                }}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </ThemeContext.Extend>
  )
}

export default CloseAuction
