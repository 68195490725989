import cx from 'classnames'
import { Box, Text } from 'grommet'
import React, { FC } from 'react'
import styles from './DetailsBox.module.scss'

const DetailsBox: FC<{ title: string; content: string[] }> = ({ title, content, children }) => {
  return (
    <Box
      border={{ size: 'small', color: 'gray-400' }}
      round="xsmall"
      pad="small"
      margin={{ bottom: 'small' }}
      direction="row"
      justify="between"
      align="start"
    >
      <Box className={cx(content.length > 3 && styles.width)}>
        <Text size="medium" color="blue-800" margin="0" weight="bold">
          {title}
        </Text>
        <Box pad={{ horizontal: 'small' }}>
          <Text size="small">
            <ul className={cx(styles.list, content.length > 3 && styles.direction)}>
              {content.map((line, index) => (
                <li key={index}>{line}</li>
              ))}
            </ul>
          </Text>
        </Box>
      </Box>
      {children}
    </Box>
  )
}

export default DetailsBox
