import React, { FC } from 'react'
import { Text, Box, Image } from 'grommet'
import carteFR from '../../../../assets/images/img-carte-fr.png'
import cartePT from '../../../../assets/images/img-carte-pt.png'
import { useIntl } from 'react-intl'
import { CurrentSaleQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'

export interface MapProps {
  vehicle: VehicleSaleQueryResult
  sale: CurrentSaleQueryResult
}

const Map: FC<MapProps> = ({ vehicle, sale }) => {
  const { formatMessage } = useIntl()
  return (
    <Box direction="row" align="center" width="50%" justify="center">
      {sale?.country === 'France' && <Image height="53px" src={carteFR} />}
      {sale?.country === 'Portugal' && <Image height="53px" src={cartePT} />}
      <Box
        height="53px"
        direction="column"
        align="start"
        justify="between"
        margin={{ left: 'small' }}
      >
        <Text weight="bold" size="medium" color="blue-800">
          {formatMessage({ id: 'client.vehicle.localisation' })}
        </Text>
        <Text size="small">
          {vehicle?.cityLocation}({vehicle?.zipCodeLocation})
        </Text>
      </Box>
    </Box>
  )
}

export default Map
