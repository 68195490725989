import React, { FC } from 'react'
import { Box, Text, Heading, Anchor } from 'grommet'
import { Logout } from 'grommet-icons'
import { Link } from '@reach/router'
import { useIntl } from 'react-intl'
import { CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { UsersLoggedListQueryResult } from 'contexts/ApiContext/services/users'
import moment from 'moment'
import 'moment/locale/fr'

const UserCounter: FC<{
  value: number
  labelId: string
  a11yTitle?: string
}> = ({ value, labelId, a11yTitle, ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <Box align="center">
      <Heading level={2} margin="xsmall" {...props}>
        {value}
      </Heading>
      <Text a11yTitle={a11yTitle} size="large" color={value ? 'blue-500' : 'red-700'} weight="bold">
        {formatMessage({ id: labelId }, { value })}
      </Text>
    </Box>
  )
}

interface OwnProps {
  sale: CurrentSaleQueryResult
  usersList: UsersLoggedListQueryResult
  connected: number
}

const Header: FC<OwnProps> = ({ sale, usersList, connected }) => {
  const { formatMessage } = useIntl()
  const dateVente = moment(sale.startDate).locale('fr')
  return (
    <Box direction="row" justify="between" wrap pad="small">
      <Box>
        <Heading level={1} margin="none" size="small">
          {formatMessage({ id: 'salesperson.header.title' }, { name: sale.room })}
        </Heading>
        <Text size="1.5rem" color="gray-500" weight="bold">
          {dateVente.format('dddd DD MMMM YYYY')} - {sale.open ? 'Ouverte' : 'Fermée'}
        </Text>
        <Box width="small">
          <Link to="/logout">
            <Anchor
              color="gray-500"
              icon={<Logout color="gray-500" />}
              label={formatMessage({ id: 'salesperson.header.logout.label' })}
            ></Anchor>
          </Link>
        </Box>
      </Box>
      <Box direction="row" gap="medium">
        <UserCounter
          value={sale.liveVisitors}
          labelId="salesperson.header.userCounter.spectator.label"
          a11yTitle={formatMessage({ id: 'salesperson.header.userCounter.spectator.title' })}
        />
        <UserCounter
          value={usersList.count}
          labelId={'salesperson.header.userCounter.identified.label'}
          data-cy="identificated-user"
          a11yTitle={formatMessage({ id: 'salesperson.header.userCounter.identified.title' })}
        />
        <UserCounter
          value={connected}
          labelId={'salesperson.header.userCounter.loggedIn.label'}
          a11yTitle={formatMessage({ id: 'salesperson.header.userCounter.loggedIn.title' })}
        />
      </Box>
    </Box>
  )
}

export default Header
