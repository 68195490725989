import React, { FC, useState } from 'react'
import { Box, Button, Layer, ThemeContext, Text, Grid } from 'grommet'
import { useIntl } from 'react-intl'

interface OwnProps {
  show: boolean
  setShow: Function
  selectTime: Function
}

interface PauseTime {
  hours?: string
  minutes?: string
}

const PauseLot: FC<OwnProps> = ({ setShow, show, selectTime }) => {
  const { formatMessage } = useIntl()
  const [hours, setHours] = useState<string>('')
  const [minutes, setMinutes] = useState<string>('')
  const [hoursMinutes, setHoursMinutes] = useState<string>('')
  const hoursList = Array.from({ length: 9 }, (v, i) => `${String(i + 10)}:00`)
  const hours2List = Array.from({ length: 25 }, (v, i) => String(i))
  const minutesList = Array.from({ length: 61 }, (v, i) => String(i))
  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      {show && (
        <Layer onEsc={() => setShow(false)} onClickOutside={() => setShow(false)}>
          <Grid
            pad="25px"
            rows={['xxxsmall', 'small']}
            columns={['medium', 'medium']}
            gap="medium"
            areas={[
              { name: 'left', start: [0, 1], end: [0, 1] },
              { name: 'right', start: [1, 1], end: [1, 1] },
            ]}
          >
            <Box gridArea="left">
              <Text
                margin={{ bottom: '20px' }}
                weight="bold"
                size="xlarge"
                alignSelf="center"
                color="black"
              >
                {formatMessage({ id: 'lot.toolbox.modal.pauseLot.choseTime' })}
              </Text>
              <Box margin={{ bottom: '20px' }}>
                <select
                  value={hoursMinutes}
                  data-cy="modal-pause-select"
                  onChange={(event) => {
                    setHoursMinutes(event.target.value)
                  }}
                >
                  <option value=""></option>
                  {hoursList.map((i, j) => {
                    return (
                      <option key={j} value={i}>
                        {i}
                      </option>
                    )
                  })}
                </select>
              </Box>
              <Button
                gap="none"
                primary
                color="green-500"
                label={formatMessage({ id: 'lot.toolbox.modal.pauseLot.validate' })}
                data-cy="modal-pause-btn"
                onClick={() => {
                  selectTime({ hours: hoursMinutes, minutes: '00' })
                  setShow(false)
                }}
              />
            </Box>
            <Box gridArea="right">
              <Text
                margin={{ bottom: '20px' }}
                weight="bold"
                size="xlarge"
                alignSelf="center"
                color="black"
              >
                {formatMessage({ id: 'lot.toolbox.modal.pauseLot.grabTime' })}
              </Text>
              <Box direction="row" margin={{ bottom: '20px' }}>
                <Box width="large" margin={{ right: '10px' }}>
                  <select
                    value={hours}
                    data-cy="modal-pause-select-hours"
                    onChange={(event) => {
                      setHours(event.target.value)
                    }}
                  >
                    <option value=""></option>
                    {hours2List.map((i, j) => {
                      return (
                        <option key={j} value={i}>
                          {i}
                        </option>
                      )
                    })}
                  </select>
                </Box>

                <Box width="large">
                  <select
                    value={minutes}
                    data-cy="modal-pause-select-minutes"
                    onChange={(event) => {
                      setMinutes(event.target.value)
                    }}
                  >
                    <option value=""></option>
                    {minutesList.map((i, j) => {
                      return (
                        <option key={j} value={i}>
                          {i}
                        </option>
                      )
                    })}
                  </select>
                </Box>
              </Box>
              <Button
                primary
                color="green-500"
                gap="none"
                label={formatMessage({ id: 'lot.toolbox.modal.pauseLot.validate' })}
                onClick={() => {
                  selectTime({ hours: hours, minutes: minutes })
                  setShow(false)
                }}
              />
            </Box>
          </Grid>
        </Layer>
      )}
    </ThemeContext.Extend>
  )
}

export default PauseLot
