export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'LIVE-VERKAUF',
  'hall.lotNumber.label': 'Nr. {number}',
  'hall.newPrice': 'Neupreis* : {price}',
  'hall.taxes.label': 'Mehrwertsteuer',
  'hall.taxes.yes': 'JA',
  'hall.taxes.no': 'NEIN',
  'client.attr.auction.loading': 'Ladevorgang läuft...',
  'client.attr.vehicle.photo_next': 'Folgendes Bild',
  'client.attr.vehicle.photo_previous': 'Vorherige Bilder',
  'client.auction.bid': 'Mitbieten',
  'client.auction.fees_extra': 'Bearbeitungsgebühren zusätzlich: {handlingFees} €',
  'client.auction.fees_included': 'Verkaufsgebühr inbegriffen',
  'client.auction.few_moments': 'in wenigen Augenblicken',
  'client.auction.losing': 'Sie sind nicht dran',
  'client.auction.lost_text': 'Sie haben diesen Gegenstand nicht ersteigert.',
  'client.auction.lot_closed': 'Los geschlossen',
  'client.auction.next_auction': 'Nächste Auktion',
  'client.auction.opening_in': 'Eröffnung der Auktion',
  'client.auction.register_to_bid': 'Registrieren Sie sich, um mitzubieten',
  'client.auction.same_amount': 'Gleiches Gebot für diesen Betrag',
  'client.auction.seconds': 'Sekunde(n)',
  'client.auction.sold': 'Zugesprochen',
  'client.auction.starting_price': 'Festlegung des Startpreises',
  'client.auction.vat_excluded': 'Ohne MwSt',
  'client.auction.winning': 'Sie sind dran',
  'client.auction.won_text1': 'Bravo! Sie haben die Versteigerung gewonnen',
  'client.auction.won_text2': 'Wir werden Sie kontaktieren',
  'client.auction.you': 'Sie',
  'client.auction.salle': 'Zimmer',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Zurück zum Katalog',
  'client.footer.footnote.one': 'Bearbeitungsgebühren zusätzlich: {handlingFees} € inkl. MwSt',
  'client.footer.footnote.three': 'Codex-Referenz zum Datum der Ausgabe oder der Originalrechnung',
  'client.footer.footnote.two':
    'Wertangabe pro rata temporis, oder Marktpreis, oder geschätzte Rate, als Hinweis gegeben, nicht haftbar für VPauto',
  'client.footer.vehicle_link': 'Detaillertes Datenblatt für dieses Los',
  'client.header.live_from': 'Live von',
  'client.header.logout': 'Abmeldung',
  'client.popup.body.first': 'Sie kehren im Bietermodus in den Auktionssaal zurück.',
  'client.popup.body.second':
    'Ein Klick auf die Schaltfläche Bieten bedeutet ein für Sie verbindliches Gebot',
  'client.popup.body.third':
    'Die Gebote werden inklusive Verkaufsgebühren abgegeben (Bearbeitungsgebühren werden extra erhoben).',
  'client.popup.confirm': 'Ich habe verstanden',
  'client.popup.header': 'Achtung',
  'client.vehicle.kilometers': 'Km',
  'client.vehicle.lot_number': 'Nr.',
  'client.vehicle.new_price': 'Neupreis',
  'client.vehicle.observations': 'Kommentar(e)',
  'client.vehicle.options': 'Ausstattung(en)',
  'client.vehicle.quote': 'Bewertung',
  'client.vehicle.vat': 'Mehrwertsteuer',
  'client.vehicle.localisation': 'Lokalisierung',
  'client.video.live_sale': 'Sehen Sie sich den Live-Verkauf an',
  'loading.app': 'Bitte warten, Anmeldung läuft...',
  'loading.sale': 'Verkauf wird geladen...',
  'loading.vehicle': 'Fahrzeug wird geladen...',
  'next_sale.content.links.catalog': 'Verkaufskatalog',
  'next_sale.content.links.sale_hours': 'Verkaufstermine',
  'next_sale.content.paused': '<bold>Verkauf</bold> pausiert',
  'next_sale.content.restored_in': '<bold>Wiederaufnahme</bold> in',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {stunde}
    =1 {stunde}
    other {stunden}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minute}
    =1 {minute}
    other {minuten}
  }`,
  'next_sale.footer.access': '<bold>Zugang</bold> wählen',
  'next_sale.footer.guide': '<bold>Anleitung</bold> für Live',
  'next_sale.footer.main_site': '<bold>Zurück</bold> zu VPauto',
  'next_sale.footer.planning': '<bold>Planung</bold> der nächsten Live-Auktionen',
  'next_sale.footer.sound': 'Verkauf <bold>anhören</bold>',
  'next_sale.footer.sound_instructions': 'Diese Funktion ist momentan nicht verfügbar.',
  'next_sale.header.live_from': '@:client.header.live_from',
}
