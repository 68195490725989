import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import withRoles, { ROLES } from 'hoc/withRoles'
import { useApi } from 'contexts/ApiContext'
import Loading from 'app/Loading'
import Auctioneer from './component'
import useLastOpenedVehicle from 'hooks/useLastOpenedVehicule'

const AuctioneerContainer: FC<RouteComponentProps> = () => {
  const { sale } = useApi()

  const { data: currentSale } = sale.useCurrentSale()

  const { data: vehiclesOrderSale } = sale.useVehiclesOrderSale(currentSale?.id, {
    enabled: currentSale,
  })

  const lastOpenedVehicle = useLastOpenedVehicle(currentSale)

  const { data: vehicle } = sale.useVehiclesSale(
    currentSale?.id,
    currentSale?.openedVehicle || lastOpenedVehicle,
    {
      enabled: currentSale,
    }
  )

  const { data: auctions } = sale.useAuctions(currentSale?.id, vehicle?.id, {
    enabled: vehicle,
  })

  if (!currentSale || !vehiclesOrderSale || !vehicle || !auctions) {
    return <Loading />
  }

  return (
    <Auctioneer
      currentSale={currentSale}
      vehiclesOrderSale={vehiclesOrderSale}
      vehicle={vehicle}
      auctions={auctions}
    />
  )
}
export default withRoles<RouteComponentProps>({
  roles: [ROLES.isAuctioneer],
})(AuctioneerContainer)
