export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'SALDI IN DIRETTA',
  'hall.lotNumber.label': 'N°{number}',
  'hall.newPrice': 'Prezzo nuovo* : {price}',
  'hall.taxes.label': 'IVA.',
  'hall.taxes.yes': 'SÌ',
  'hall.taxes.no': 'NO',
  'client.attr.auction.loading': 'Caricamento...',
  'client.attr.vehicle.photo_next': 'Immagine successiva',
  'client.attr.vehicle.photo_previous': 'Immagine precedente',
  'client.auction.bid': 'Fare offerte',
  'client.auction.fees_extra': 'Spese di pratica in aggiunta: {handlingFees} €',
  'client.auction.fees_included': 'Spese di vendita incluse',
  'client.auction.few_moments': 'tra qualche istante',
  'client.auction.losing': 'Non sei il miglior offerente',
  'client.auction.lost_text': "Non ti sei aggiudicato l'asta.",
  'client.auction.lot_closed': 'Lotto chiuso',
  'client.auction.next_auction': 'Prossima asta',
  'client.auction.opening_in': 'Apertura delle aste',
  'client.auction.register_to_bid': 'Iscriviti per fare offerte',
  'client.auction.same_amount': 'Stessa offerta per questo importo',
  'client.auction.seconds': 'Secondo/i',
  'client.auction.sold': 'Aggiudicato',
  'client.auction.starting_price': "Base d'asta",
  'client.auction.vat_excluded': 'IVA esclusa',
  'client.auction.winning': 'Sei il miglior offerente',
  'client.auction.won_text1': "Complimenti! ti sei aggiudicato l'asta",
  'client.auction.won_text2': 'Ti contatteremo.',
  'client.auction.you': 'Tu',
  'client.auction.salle': 'Sala',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Torna al catalogo',
  'client.footer.footnote.one': 'Spese di pratica in aggiunta: {handlingFees} € tasse incluse',
  'client.footer.footnote.three': 'Codice di referenza alla data di uscita o fattura originale',
  'client.footer.footnote.two':
    'Quotazione Prorata temporis, o prezzo di mercato, o quotazione stimata, data a titolo indicativo, non impegnando la responsabilità di VPauto',
  'client.footer.vehicle_link': 'Scheda dettagliata del lotto',
  'client.header.live_from': 'In diretta da',
  'client.header.logout': 'Disconnessione',
  'client.popup.body.first': 'Entra in sala in modalità offerente.',
  'client.popup.body.second':
    "Un clic sul tasto Fare un'offerta rappresenta un'offerta e ti impegna.",
  'client.popup.body.third':
    'Le offerte includono le spese di vendita (le spese di pratica sono in aggiunta).',
  'client.popup.confirm': 'Ho capito',
  'client.popup.header': 'Attenzione',
  'client.vehicle.kilometers': 'Km',
  'client.vehicle.lot_number': 'N°',
  'client.vehicle.new_price': 'Prezzo veicolo nuovo',
  'client.vehicle.observations': 'Osservazione/i',
  'client.vehicle.options': 'Equipaggiamento/i',
  'client.vehicle.quote': 'Quotazione',
  'client.vehicle.vat': 'IVA',
  'client.vehicle.localisation': 'Localizzazione',
  'client.video.live_sale': 'Guarda la vendita dal vivo',
  'loading.app': 'Attendere prego, connessione in corso...',
  'loading.sale': 'Caricamento della vendita in corso...',
  'loading.vehicle': 'Caricamento del veicolo in corso...',
  'next_sale.content.links.catalog': 'Catalogo della vendita',
  'next_sale.content.links.sale_hours': 'Orari della vendita',
  'next_sale.content.paused': '<bold>Vendita</bold> in pausa',
  'next_sale.content.restored_in': '<bold>Ripresa</bold> tra',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {ora}
    =1 {ora}
    other {ore}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minuto}
    =1 {minuto}
    other {minuti}
  }`,
  'next_sale.footer.access': 'Scegliere il proprio <bold>accesso</bold>',
  'next_sale.footer.guide': "<bold>Modalità d'impiego</bold> del Live",
  'next_sale.footer.main_site': '<bold>Torna</bold> a VPauto',
  'next_sale.footer.planning': '<bold>Planning</bold> dei prossimi Live',
  'next_sale.footer.sound': '<bold>Ascoltare</bold> la vendita',
  'next_sale.footer.sound_instructions': 'Questa funzione non è disponibile al momento',
  'next_sale.header.live_from': '@:client.header.live_from',
}
