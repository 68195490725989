import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useApi } from 'contexts/ApiContext'
import Loading from 'app/Loading'
import Salesperson from './component'
import withRoles, { ROLES } from 'hoc/withRoles'

const SalespersonContainer: FC<RouteComponentProps> = () => {
  const { users } = useApi()
  const { data: usersList, refetch } = users.useUsersLoggedList()

  if (!usersList) {
    return <Loading />
  }

  return (
    <Salesperson
      refresh={refetch}
      users={usersList?.users.sort((user) => (user.logged ? -1 : 1))}
    />
  )
}
export default withRoles<RouteComponentProps>({
  roles: [ROLES.isSalesperson],
})(SalespersonContainer)
