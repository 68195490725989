import React, { FC } from 'react'
import { Main, Grid, Box, Text, Image, Stack } from 'grommet'
import Specifications from './Specifications'
import { useIntl } from 'react-intl'
import {
  CurrentSaleQueryResult,
  VehicleSaleQueryResult,
  AuctionQueryResult,
} from 'contexts/ApiContext/services/sale'
import Auctions from './Auctions'
import styles from './Hall.module.scss'
import { useAuth } from 'contexts/AuthContext'
import { Languages } from 'translations'
import Waiting from './Auctions/Waiting'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
}

const Hall: FC<OwnProps> = ({ vehicle, currentSale, auctions }) => {
  const { formatMessage } = useIntl()
  const { user } = useAuth()
  return (
    <Main data-cy="room-screen">
      {!currentSale.paused && (
        <Grid fill columns={['60%', '40%']} rows={['1fr']}>
          <Box fill background="gray-300" direction="column" justify="between">
            <Stack>
              <Box height="medium" align="center">
                <Image
                  fit="contain"
                  src={vehicle?.photo}
                  width="600"
                  height="450"
                  data-cy="img-room-screen"
                />
                {vehicle.salingState === 'Adjugé' && !vehicle.lotOpen && (
                  <div
                    className={styles.sold}
                    style={{
                      backgroundImage: `url(${require(`../../assets/images/adjuge/adjuge-${
                        user.lang as Languages
                      }.png`)})`,
                    }}
                  ></div>
                )}
              </Box>
              <Box direction="row">
                <Box
                  background="blue-800"
                  pad="small"
                  margin={{ left: 'medium' }}
                  elevation="medium"
                >
                  <Text
                    size="80px"
                    weight="bold"
                    margin={{ horizontal: 'medium' }}
                    data-cy="lot-number-room-screen"
                  >
                    {formatMessage({ id: 'hall.lotNumber.label' }, { number: vehicle.lot })}
                  </Text>
                </Box>
              </Box>
            </Stack>
            <Specifications vehicle={vehicle} data-cy="specifications-vehicle" />
          </Box>
          <Box>
            <Auctions
              data-cy="auctions-room-screen"
              vehicle={vehicle}
              auctions={auctions}
              gridArea="auctions"
              currentSale={currentSale}
            />
          </Box>
        </Grid>
      )}
      {currentSale.paused && (
        <>
          <Box background="blue-800" pad="small" direction="row" justify="around" align="center">
            <Text size="80px" textAlign="center" weight="bold">
              {formatMessage({ id: 'hall.title' })} {currentSale.name}
            </Text>
          </Box>
          <Box margin={{ top: '20%' }}>
            <Waiting vehicle={vehicle} paused data-cy="waiting-room-screen" />
          </Box>
        </>
      )}
    </Main>
  )
}

export default Hall
