import React, { FC } from 'react'
import { Main } from 'grommet'
import ResponsiveGrid from 'components/ResponsiveGrid'
import Lot from '../components/Lot'
import AuctionsTable from '../components/AuctionsTable'
import Dashboard from './Dashboard'
import {
  AuctionQueryResult,
  CurrentSaleQueryResult,
  VehicleOrderSaleQueryResult,
  VehicleSaleQueryResult,
} from 'contexts/ApiContext/services/sale'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
}

const Auctioneer: FC<OwnProps> = ({ vehicle, vehiclesOrderSale, currentSale, auctions }) => {
  return (
    <Main>
      <ResponsiveGrid
        fill
        columns={{
          small: ['100%'],
          medium: ['minmax(min-content, 33.33%)', '1fr'],
        }}
        rows={{
          small: ['min-content', 'min-content', 'min-content'],
          medium: ['1fr', 'min-content'],
        }}
        areas={{
          xsmall: [
            { name: 'lot', start: [0, 0], end: [0, 0] },
            { name: 'auctions', start: [0, 1], end: [0, 1] },
            { name: 'dashboard', start: [0, 2], end: [0, 2] },
          ],
          medium: [
            { name: 'lot', start: [0, 0], end: [0, 1] },
            { name: 'auctions', start: [1, 0], end: [1, 0] },
            { name: 'dashboard', start: [1, 1], end: [1, 1] },
          ],
        }}
      >
        <Lot
          currentSale={currentSale}
          vehicle={vehicle}
          vehiclesOrderSale={vehiclesOrderSale}
          gridArea="lot"
        ></Lot>
        <AuctionsTable
          gridArea="auctions"
          auctions={auctions}
          vehicle={vehicle}
          currentSale={currentSale}
        ></AuctionsTable>
        <Dashboard currentSale={currentSale} vehicle={vehicle} gridArea="dashboard" />
      </ResponsiveGrid>
    </Main>
  )
}

export default Auctioneer
