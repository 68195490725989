import React, { FC } from 'react'
import { Box, Text } from 'grommet'
import { Currency } from 'components/Number'

const Pricing: FC<{ value: number; label: string; sup?: number }> = ({ value, label, sup }) => {
  return (
    <Box align="center" margin={{ top: 'small', horizontal: 'medium' }}>
      <Text size="large">
        {label} {sup && <sup>{sup}</sup>}
      </Text>
      <Currency value={value} size="large" />
    </Box>
  )
}

export default Pricing
