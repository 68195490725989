import React, { FC, useCallback } from 'react'
import CloseSale from './component'
import { CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { useApi } from 'contexts/ApiContext'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  show: boolean
  setShow: Function
}

const CloseSaleContainer: FC<OwnProps> = ({ currentSale, show, setShow }) => {
  const { sale } = useApi()

  const [mutateCloseSale] = sale.useCloseSale()

  const closeSale = useCallback(() => {
    mutateCloseSale({
      saleId: currentSale.id,
    })
  }, [currentSale, mutateCloseSale])

  return <CloseSale show={show} setShow={setShow} closeSale={closeSale} />
}
export default CloseSaleContainer
