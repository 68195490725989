import Carousel from 'app/components/Carousel/component'
import { VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import React, { FC } from 'react'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
}

const Slider: FC<OwnProps> = ({ vehicle }) => {
  return (
    <div>
      {/* force carousel to reload, must be changed */}
      {vehicle.lotOpen && (
        <Carousel vehicle={vehicle} gridArea="html" screenSlider={true} autoplay />
      )}
      {!vehicle.lotOpen && (
        <Carousel vehicle={vehicle} gridArea="html" screenSlider={true} autoplay />
      )}
    </div>
  )
}

export default Slider
