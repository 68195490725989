import React, { FC, useState } from 'react'
import { Box, Button, Layer, TextInput, ThemeContext, Text } from 'grommet'
import { useIntl } from 'react-intl'
import { VehicleOrderSaleQueryResult } from 'contexts/ApiContext/services/sale'

interface OwnProps {
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
  changeVehicle: Function
  show: boolean
  setShow: Function
}

const ChangeLot: FC<OwnProps> = ({ changeVehicle, show, setShow, vehiclesOrderSale }) => {
  const { formatMessage } = useIntl()
  const [newLot, setNewLot] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      {show && (
        <Layer onEsc={() => setShow(false)} onClickOutside={() => setShow(false)}>
          <Box background="white" round="xsmall" pad="xsmall" margin="small" gap="xsmall" width="">
            <Box pad="xsmall">
              <Text weight="bold" size="xlarge" alignSelf="center" color="black">
                {formatMessage({ id: 'lot.toolbox.modal.changeLot.title' })}
              </Text>
              {error && (
                <Text
                  weight="bold"
                  size="large"
                  alignSelf="center"
                  color="status-critical"
                  data-cy="lot-no-exist"
                >
                  {formatMessage({ id: 'lot.toolbox.modal.changeLot.error' })}
                </Text>
              )}
            </Box>
            <Box pad="xsmall" margin="small" direction="row" gap="xsmall">
              <TextInput
                data-cy="input-change-lot"
                value={newLot}
                onChange={(event) => {
                  setNewLot(event.target.value)
                }}
              />
              <Button
                data-cy="submit-change-lot"
                primary
                color="blue-500"
                gap="none"
                label={formatMessage({ id: 'lot.toolbox.modal.changeLot.validate' })}
                onClick={() => {
                  const vehicleSale = vehiclesOrderSale.find(
                    ({ realOrder }) => realOrder === parseInt(newLot)
                  ) as VehicleOrderSaleQueryResult

                  if (vehicleSale) {
                    setNewLot('')
                    changeVehicle(vehicleSale.id)
                    setShow(false)
                    setError(false)
                  } else {
                    setError(true)
                    setNewLot('')
                  }
                }}
                disabled={!newLot}
              />
              <Button
                gap="none"
                primary
                color="red-600"
                label={formatMessage({ id: 'lot.toolbox.modal.changeLot.close' })}
                onClick={() => {
                  setShow(false)
                  setError(false)
                }}
              ></Button>
            </Box>
          </Box>
        </Layer>
      )}
    </ThemeContext.Extend>
  )
}

export default ChangeLot
