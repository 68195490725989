import React, { FC } from 'react'
import { Box, Button, Layer, ThemeContext, Text } from 'grommet'
import { useIntl } from 'react-intl'

interface OwnProps {
  show: boolean
  setShow: Function
}

const InformationModal: FC<OwnProps> = ({ setShow, show }) => {
  const { formatMessage } = useIntl()

  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          data-cy="modal-info-customer"
        >
          <Box background="white" round="xsmall" pad="xsmall" margin="small" gap="xsmall" width="">
            <Box pad="xsmall">
              <Text weight="bold" size="xlarge" alignSelf="center" color="black">
                {formatMessage({ id: 'client.popup.header' })}
              </Text>
            </Box>{' '}
            <Box>
              <Text size="medium" color="black">
                {formatMessage({ id: 'client.popup.body.first' })}
              </Text>
            </Box>
            <Box>
              <Text size="medium" color="black">
                {formatMessage({ id: 'client.popup.body.second' })}
              </Text>
            </Box>
            <Box>
              <Text size="medium" color="black">
                {formatMessage({ id: 'client.popup.body.third' })}
              </Text>
            </Box>
            <Box
              pad="xsmall"
              margin="small"
              align="center"
              justify="center"
              direction="row"
              gap="xsmall"
            >
              <Button
                data-cy="close-pop-up"
                gap="none"
                primary
                color="red-600"
                label={formatMessage({ id: 'client.popup.confirm' })}
                type="submit"
                onClick={() => {
                  setShow(false)
                }}
              ></Button>
            </Box>
          </Box>
        </Layer>
      )}
    </ThemeContext.Extend>
  )
}

export default InformationModal
