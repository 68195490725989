import React, { FC } from 'react'
import cn from 'classnames'
import { Box } from 'grommet'

import styles from './Triangle.module.scss'

type Direction = 'left' | 'right' | 'bottom' | 'top'

const SIDE_REVERSE: Record<Direction, Direction> = {
  left: 'right',
  right: 'left',
  bottom: 'top',
  top: 'bottom',
}

const Triangle: FC<{
  direction?: Direction
  color?: string
}> = ({ direction = 'right', color = 'gray-500' }) => {
  return (
    <Box
      className={cn(styles.triangle, styles[direction])}
      border={[
        {
          color: 'transparent',
          side: 'all',
        },
        {
          color,
          side: SIDE_REVERSE[direction],
        },
      ]}
    ></Box>
  )
}

export default Triangle
