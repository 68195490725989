import { navigate } from '@reach/router'
import React, { FC, useState, useEffect } from 'react'
import loadListeners from './listeners'

/**
 * The socket.io-client version is too old. It only works with global import in the `public/index.html` file.
 * The packet 'socket.io-client' is installed to have a typing.
 */

interface WebSocketInterface {
  socket: SocketIOClient.Socket | null
  loading: boolean
}

const WebSocketContext = React.createContext<WebSocketInterface>({ socket: null, loading: true })

interface SocketWrapper extends SocketIOClient.Socket {
  socket: SocketIOClient.Socket
}

interface OwnProps {
  locale: string
}

export const WebSocketProvider: FC<OwnProps> = (props) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const wrapper = (window.io.connect(process.env.REACT_APP_WS_URI as string, {
      transports: ['websocket', 'polling', 'flashsocket'],
    }) as unknown) as SocketWrapper

    setSocket(wrapper.socket)
    wrapper.on('connect', () => {
      setLoading(false)
      loadListeners(wrapper, props.locale)
    })
    socket?.on('error', (err: string) => {
      setLoading(false)
      navigate('/error')
    })
  }, [props.locale, socket])

  return <WebSocketContext.Provider value={{ socket: socket, loading: isLoading }} {...props} />
}

export const useWS = (): WebSocketInterface => React.useContext(WebSocketContext)
