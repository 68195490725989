export default {
  'home.title': 'VPAuto live',
  'home.loading': 'Chargement...',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Pause de vente',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultPitch': 'Pas par défaut',
  'operator.dashboard.bidPitch': 'Pas des enchères',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',

  'customer.header.liveFrom': 'En direct de {name}',
  'customer.details.notes.fees': 'Frais de dossier en sus : 150 € TTC',
  'customer.details.notes.quotation':
    "Cote prorata temporis, ou prix de marché, ou cote estimative, donné(e) à titre indicatif, n'engageant pas la responsabilité de VPauto",
  'customer.details.notes.newPrice': "Référence Codex à la date de sortie ou facture d'origine",
  'customer.details.pricing.newPrice': 'Prix neuf',
  'customer.details.pricing.quotation': 'Cote',
  'customer.details.notes.title':
    '{notes, plural, zero {Observation} one {Observation} other {Observations}} :',
  'customer.details.equipment.title':
    '{equipment, plural, zero {Équipement} one {Équipement} other {Équipements}} :',
  'customer.auctions.timer.seconds':
    '{seconds, plural, zero {seconde} one {seconde} other {secondes}}',
  'customer.auctions.bid.buyer.you': 'Vous :',
  'customer.auctions.bid.buyer.live': 'Live :',
  'customer.auctions.bid.buyer.hall': 'Salle :',

  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'VENTE LIVE',
  'hall.lotNumber.label': 'N°{number}',
  'hall.newPrice': 'Prix neuf* : {price}',
  'hall.taxes.label': 'TVA',
  'hall.taxes.yes': 'OUI',
  'hall.taxes.no': 'NON',
  'client.attr.auction.loading': 'Chargement...',
  'client.attr.vehicle.photo_next': 'Image suivante',
  'client.attr.vehicle.photo_previous': 'Image précédente',
  'client.auction.bid': 'Enchérir à',
  'client.auction.fees_extra': 'Frais de dossier en sus : {handlingFees} €',
  'client.auction.fees_included': 'Frais de vente inclus',
  'client.auction.few_moments': 'dans quelques instants',
  'client.auction.losing': "Vous n'avez pas la main",
  'client.auction.lost_text': "Vous n'avez pas remporté l'enchère.",
  'client.auction.lot_closed': 'Lot cloturé',
  'client.auction.next_auction': 'Prochaine enchère',
  'client.auction.opening_in': 'Ouverture des enchères',
  'client.auction.register_to_bid': 'Inscrivez-vous pour enchérir',
  'client.auction.same_amount': 'Même enchère pour ce montant',
  'client.auction.seconds': 'Seconde(s)',
  'client.auction.sold': 'Adjugé',
  'client.auction.starting_price': 'Mise à prix',
  'client.auction.vat_excluded': 'Hors taxes',
  'client.auction.winning': 'Vous avez la main',
  'client.auction.won_text1': "Bravo ! vous avez gagné l'enchère",
  'client.auction.won_text2': 'Nous allons vous contacter.',
  'client.auction.you': 'Vous',
  'client.auction.salle': 'Salle',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Retour au catalogue',
  'client.footer.footnote.one': 'Frais de dossier en sus : {handlingFees} € TTC',
  'client.footer.footnote.three': "Référence Codex à la date de sortie ou facture d'origine",
  'client.footer.footnote.two':
    "Cote prorata temporis, ou prix de marché, ou cote estimative, donné(e) à titre indicatif, n'engageant pas la responsabilité de VPauto",
  'client.footer.vehicle_link': 'Fiche détaillée du lot',
  'client.header.live_from': 'En direct de',
  'client.header.logout': 'Déconnexion',
  'client.popup.body.first': 'Vous rentrez dans la salle en mode enchérisseur.',
  'client.popup.body.second':
    'Un clic sur le bouton Enchérir représente une enchère et vous engage.',
  'client.popup.body.third':
    'Les enchères sont portées frais de vente inclus (frais de dossier en sus).',
  'client.popup.confirm': "J'ai compris",
  'client.popup.header': 'Attention',
  'client.vehicle.kilometers': 'Km',
  'client.vehicle.lot_number': 'N°',
  'client.vehicle.new_price': 'Prix neuf',
  'client.vehicle.observations': 'Observation(s)',
  'client.vehicle.options': 'Equipement(s)',
  'client.vehicle.quote': 'Cote',
  'client.vehicle.vat': 'TVA',
  'client.vehicle.localisation': 'Localisation',
  'client.video.live_sale': 'Voir la vente en direct',
  'loading.app': 'Veuillez patienter, connexion en cours...',
  'loading.sale': 'Chargement de la vente en cours...',
  'loading.vehicle': 'Chargement du véhicule en cours...',
  'next_sale.content.links.catalog': 'Catalogue de la vente',
  'next_sale.content.links.sale_hours': 'Horaires de la vente',
  'next_sale.content.paused': '<bold>Vente</bold> en pause',
  'next_sale.content.restored_in': '<bold>Reprise</bold> dans',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {heure}
    =1 {heure}
    other {heures}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minute}
    =1 {minute}
    other {minutes}
  }`,
  'next_sale.footer.access': 'Choisir son <bold>accès</bold>',
  'next_sale.footer.guide': "<bold>Mode d'emploi</bold> du Live",
  'next_sale.footer.main_site': '<bold>Retour</bold> à VPauto',
  'next_sale.footer.planning': '<bold>Planning</bold> des prochains Live',
  'next_sale.footer.sound': '<bold>Ecouter</bold> la vente',
  'next_sale.footer.sound_instructions': "Cette fonctionnalité n'est pas disponible actuellement.",
}
