export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'VENDA AO VIVO',
  'hall.lotNumber.label': 'N.°{number}',
  'hall.newPrice': 'Novo preço* : {price}',
  'hall.taxes.label': 'IVA',
  'hall.taxes.yes': 'SIM',
  'hall.taxes.no': 'NÃO',
  'client.attr.auction.loading': 'A carregar...',
  'client.attr.vehicle.photo_next': 'Imagem seguinte',
  'client.attr.vehicle.photo_previous': 'Imagem anterior',
  'client.auction.bid': 'Licitar',
  'client.auction.fees_extra': 'Despesas administrativas adicionais: {handlingFees} €',
  'client.auction.fees_included': 'Despesas de venda incluídas',
  'client.auction.few_moments': 'dentro de alguns instantes',
  'client.auction.losing': 'Perdeu',
  'client.auction.lost_text': 'Não ganhou o leilão.',
  'client.auction.lot_closed': 'Lote encerrado',
  'client.auction.next_auction': 'Próximo leilão',
  'client.auction.opening_in': 'Abertura das licitações',
  'client.auction.register_to_bid': 'Registe-se para licitar',
  'client.auction.same_amount': 'A mesma licitação para este montante',
  'client.auction.seconds': 'Segundo(s)',
  'client.auction.sold': 'Vendido',
  'client.auction.starting_price': 'Base de licitação',
  'client.auction.vat_excluded': 'IVA Excluído',
  'client.auction.winning': 'Ganhou',
  'client.auction.won_text1': 'Muito bem! ganhou o leilão',
  'client.auction.won_text2': 'Entraremos em contacto consigo.',
  'client.auction.you': 'O utilizador',
  'client.auction.salle': 'Corredor',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Voltar ao catálogo',
  'client.footer.footnote.one':
    'Despesas administrativas adicionais: {handlingFees} € (incluindo taxas)',
  'client.footer.footnote.three': 'Referência do Codex na data de lançamento ou da fatura original',
  'client.footer.footnote.two':
    'Cotação prorata temporis, ou preço de mercado, ou cotação estimada, dada a título indicativo, não envolvendo a responsabilidade da VPauto',
  'client.footer.vehicle_link': 'Ficha detalhada do lote',
  'client.header.live_from': 'Em direto de',
  'client.header.logout': 'Terminar sessão',
  'client.popup.body.first': 'Entra no salão como licitante.',
  'client.popup.body.second': 'Um clique no botão Licitar representa uma licitação e compromete-o.',
  'client.popup.body.third':
    'As licitações incluem as despesas de venda incorridas (além de despesas administrativas).',
  'client.popup.confirm': 'Compreendi',
  'client.popup.header': 'Atenção',
  'client.vehicle.kilometers': 'km',
  'client.vehicle.lot_number': 'N.°',
  'client.vehicle.new_price': 'Novo preço',
  'client.vehicle.observations': 'Observação(ões)',
  'client.vehicle.options': 'Equipamento(s)',
  'client.vehicle.quote': 'Cotação',
  'client.vehicle.vat': 'IVA',
  'client.vehicle.localisation': 'Localização',
  'client.video.live_sale': 'Veja a venda ao vivo',
  'loading.app': 'Aguarde, ligação em curso...',
  'loading.sale': 'A carregar a venda...',
  'loading.vehicle': 'A carregar o veículo...',
  'next_sale.content.links.catalog': 'Catálogo da venda',
  'next_sale.content.links.sale_hours': 'Horários da venda',
  'next_sale.content.paused': '<bold>Venda</bold> em pausa',
  'next_sale.content.restored_in': '<bold>Retoma</bold> dentro de',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {hora}
    =1 {hora}
    other {horas}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minuto}
    =1 {minuto}
    other {minutos}
  }`,
  'next_sale.footer.access': 'Escolher o seu <bold>acesso</bold>',
  'next_sale.footer.guide': '<bold>Instruções de utilização</bold> do modo em direto',
  'next_sale.footer.main_site': '<bold>Voltar</bold> a VPauto',
  'next_sale.footer.planning': '<bold>Planeamento</bold> dos próximos modos em direto',
  'next_sale.footer.sound': '<bold>Escutar</bold> a venda',
  'next_sale.footer.sound_instructions': 'Atualmente, esta funcionalidade não está disponível.',
  'next_sale.header.live_from': '@:client.header.live_from',
}
