import React, { FC } from 'react'
import { Box, Text } from 'grommet'
import { useIntl } from 'react-intl'
import Chrono from 'assets/Chrono'
import Timer, { Status } from 'services/timer'

export interface TimerCProps {
  tick: number | null
  textNumberSize?: string
  showSeconds?: boolean
}

const TimerC: FC<TimerCProps> = ({
  tick,
  textNumberSize = '3.5rem',
  showSeconds = true,
  ...props
}) => {
  const { formatMessage } = useIntl()

  return (
    <Box
      direction="row"
      background="white"
      pad="medium"
      fill={false}
      justify="center"
      align="center"
      round="small"
      border={{ size: 'xsmall', color: 'gray-300' }}
      {...props}
    >
      <Box width="80px">
        <Text color="orange-600">
          <Chrono />
        </Text>
      </Box>
      <Box align="center" margin={{ left: 'small' }}>
        <Text color="gray-700" size={textNumberSize} weight="bold">
          {Timer.getInstance().status === Status.RUNNING ? tick && tick : 0}
        </Text>
        {showSeconds && (
          <Text color="gray-700" size="large">
            {formatMessage({ id: 'client.auction.seconds' })}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default TimerC
