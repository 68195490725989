import React, { FC } from 'react'
import { Grid, Box, BoxProps, Text } from 'grommet'
import { VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'

const Cell: FC<BoxProps> = ({ children, ...props }) => {
  const innerChild =
    typeof children === 'string' ? (
      <Text weight="bold" textAlign="center" size="huge">
        {children}
      </Text>
    ) : (
      children
    )
  return (
    <Box background="white" pad={{ vertical: 'small' }} round="xsmall" {...props}>
      {innerChild}
    </Box>
  )
}

interface OwnProps {
  vehicle: VehicleSaleQueryResult
}

const Specifications: FC<OwnProps> = ({ vehicle, ...props }) => {
  return (
    <Grid
      {...props}
      margin="small"
      gap="xsmall"
      columns={['1fr', '1fr']}
      rows={['fit', 'fit', 'fit', 'fit', 'fit']}
      areas={[
        {
          name: 'name',
          start: [0, 0],
          end: [1, 0],
        },
        {
          name: 'date',
          start: [0, 1],
          end: [0, 1],
        },
        {
          name: 'km',
          start: [1, 1],
          end: [1, 1],
        },
        {
          name: 'class',
          start: [0, 2],
          end: [0, 2],
        },
        {
          name: 'quotation',
          start: [1, 2],
          end: [1, 2],
        },
        {
          name: 'options',
          start: [0, 3],
          end: [1, 3],
        },
        {
          name: 'observations',
          start: [0, 4],
          end: [1, 4],
        },
      ]}
    >
      <Cell gridArea="name">{vehicle?.model}</Cell>
      <Cell gridArea="date">{vehicle?.registrationDate}</Cell>
      <Cell gridArea="km">{vehicle?.kilometers + ' KM'}</Cell>
      <Cell gridArea="class">{vehicle?.class}</Cell>
      <Cell gridArea="quotation">{vehicle?.energyname}</Cell>
      <Cell gridArea="options" direction="row">
        {vehicle?.options.length > 0 && (
          <Box width="50%">
            <ul>
              {vehicle?.options.slice(0, 3).map((option, i) => (
                <li key={i}>
                  <Text size="huge">{option}</Text>
                </li>
              ))}
            </ul>
          </Box>
        )}
        {vehicle?.options.length > 3 && (
          <Box width="50%">
            <ul>
              {vehicle?.options.slice(3, 6).map((option, i) => (
                <li key={i}>
                  {' '}
                  <Text size="huge">{option}</Text>
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Cell>
      <Cell gridArea="observations">
        {vehicle?.obsA.length > 0 && (
          <ul>
            {vehicle?.obsA.slice(0, 3).map((observation, i) => (
              <li key={i}>
                {' '}
                <Text size="huge">{observation}</Text>
              </li>
            ))}
          </ul>
        )}
      </Cell>
    </Grid>
  )
}

export default Specifications
