import React, { FC } from 'react'
import { Link } from '@reach/router'
import { Box, Text, BoxProps } from 'grommet'

import styles from './LinkBox.module.scss'

interface LinkBoxProps extends BoxProps {
  label: string
  to: string
  icon?: React.ReactNode
  background?: string
}

const LinkBox: FC<LinkBoxProps> = ({ label, to, icon, background = 'gray-300', ...props }) => {
  return (
    <Link to={to} className={styles.link} {...props}>
      <Box align="center">
        <Box
          background={background}
          align="center"
          justify="center"
          pad="medium"
          round="medium"
          width="xsmall"
          height="xsmall"
          margin={{ bottom: 'xsmall' }}
        >
          {icon}
        </Box>
        <Text color="gray-800">{label}</Text>
      </Box>
    </Link>
  )
}

export default LinkBox
