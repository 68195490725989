import React, { FC } from 'react'
import { Box, Text } from 'grommet'
import {
  VehicleSaleQueryResult,
  CurrentSaleQueryResult,
  AuctionQueryResult,
} from 'contexts/ApiContext/services/sale'
import { useAuth } from 'contexts/AuthContext'
import { useIntl } from 'react-intl'
import Bids from 'app/components/Bids'
import HandlingFees from '../../../components/HandlingFees'
import { calculateVatFreePrice } from 'services/helpers'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  currentSale: CurrentSaleQueryResult
  auctions: AuctionQueryResult[]
}

const Biding: FC<OwnProps> = ({ vehicle, currentSale, auctions }) => {
  const { formatMessage } = useIntl()
  const { user } = useAuth()

  const lastAuction = vehicle.lastAuctions?.[0]

  const currentPrice = lastAuction ? lastAuction.amount + vehicle.pricePitch : vehicle.startingPrice

  return (
    <>
      {vehicle.auctionOpen && !vehicle.pausedAt && (
        <>
          <Box align="center">
            <Text weight="bold" size="40px">
              {formatMessage({ id: 'client.auction.next_auction' })}
            </Text>
            <Box
              border={{ color: 'red', size: 'medium' }}
              pad="xsmall"
              round="xsmall"
              margin={{ vertical: 'small' }}
            >
              <Text size="50px">{currentPrice} €</Text>
            </Box>
            <Text size="30px">{formatMessage({ id: 'client.auction.fees_included' })}</Text>
            {currentSale.id !== '48D9A9642FFE3AD7C1258BDD003103C5' && (
              <Text size="30px">
                <HandlingFees currentSale={currentSale} />
              </Text>
            )}
          </Box>
          {vehicle.vat === true && (vehicle.category === 'Utilitaire' || user.isForeigner) && (
            <Box align="center">
              <Text>
                {formatMessage({ id: 'client.auction.vat_excluded' })} :{' '}
                {calculateVatFreePrice(currentPrice, vehicle.vatAmount)} €
              </Text>
            </Box>
          )}
        </>
      )}

      <Box align="center" margin={{ vertical: 'small' }} pad={{ horizontal: 'medium' }}>
        <Bids auctions={auctions} />
      </Box>
    </>
  )
}

export default Biding
