import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { AuctionQueryResult } from 'contexts/ApiContext/services/sale'
import { useAuth } from 'contexts/AuthContext'
import { Origin } from 'app/components/Bid/component'
import Bid from '../Bid'

const Bids: FC<{
  auctions: AuctionQueryResult[]
  onIntervalEnds?: Function
}> = ({ auctions, onIntervalEnds, ...props }) => {
  const { user } = useAuth()
  const [count, setCount] = useState(0)

  useEffect(() => {
    let counter = count
    const interval = setInterval(() => {
      if (counter >= auctions.length) {
        clearInterval(interval)
        onIntervalEnds && onIntervalEnds()
      } else {
        setCount((count) => count + 1)
        counter++ // local variable that this closure will see
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [auctions, count])

  return (
    <>
      {[...auctions]
        .reverse()
        .slice(0, count)
        .reverse()
        .slice(0, 5)
        .map(({ id, amount, type, user: { name } }, i) => (
          <Bid
            data-cy="bid"
            key={id}
            value={amount}
            origin={name === user.name ? 'you' : (type.toLocaleLowerCase() as Origin)}
            level={i}
            width={i === 0 ? '75%' : '65%'}
          />
        ))}
    </>
  )
}

export default Bids
