import React, { FC, Suspense } from 'react'
import { navigate, Router } from '@reach/router'
import Auctioneer from './Auctioneer'
import Salesperson from './Salesperson'
import Loading from './Loading'
import BlockUser from './BlockUser'
import Customer from './Customer'
import Home from './Home'
import Operator from './Operator'
import Error from './Error'
import NoSale from './NoSale'
import Logout from './Logout'
import { useAuth } from 'contexts/AuthContext'
import { useWS } from 'contexts/WebSocketContext'
import { useLoader } from 'contexts/LoaderContext'
import Hall from './Hall'
import Slider from './Slider'
import NextSale from './NextSale'
import { useApi } from 'contexts/ApiContext'

const AppRouter: FC = () => {
  const {
    user: { isLoggedIn, isAuctioneer, isOperator },
  } = useAuth()

  const { socket, loading: loadingSocket } = useWS()
  const isLoading = useLoader()

  const { sale } = useApi()

  const { data: currentSale } = sale.useCurrentSale()

  if (isLoggedIn && !isAuctioneer && !isOperator && !currentSale?.paused) {
    navigate('/client')
  }

  if (!isLoggedIn || loadingSocket) {
    return <Loading />
  }

  if (!socket?.connected) {
    return <Error />
  }

  return (
    <Suspense fallback={<Loading />}>
      {isLoading && <BlockUser />}
      <Router style={{ height: '100%', width: '100%' }}>
        <Home path="/" />
        <Auctioneer path="/auctioneer" />
        <Salesperson path="/salesperson" />
        <Customer path="/client" />
        <Hall path="/room-screen" />
        <Operator path="/operator" />
        <Error path="/error" />
        <NoSale path="/no-sale" />
        <Slider path="/slider" />
        <Logout path="/logout" />
        <NextSale path="/next-sale" />
      </Router>
    </Suspense>
  )
}

export default AppRouter
