import React, { FC } from 'react'
import styles from './BlockUser.module.scss'
import Loader from './Loader'
const BlockUser: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  )
}

export default BlockUser
