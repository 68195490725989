import { MutationResultPair, queryCache, useMutation } from 'react-query'
import { callApi } from './utils'
import { User } from 'contexts/AuthContext/types'

export const useMutationLogin = (): MutationResultPair<User, Error, { token: string }, string> => {
  return useMutation(
    async ({ token }) => {
      const { data } = await callApi<User>('live/api/users/logged', {
        method: 'GET',
        params: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          access_token: token,
        },
      })
      return data
    },
    {
      throwOnError: true,
      onSuccess: (data) => {
        queryCache.setQueryData('loggedUser', data)
      },
    }
  )
}
