export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'ÉLŐ AKCIÓ',
  'hall.lotNumber.label': 'N°{number}',
  'hall.newPrice': 'Új ár* : {price}',
  'hall.taxes.label': 'ÁFA',
  'hall.taxes.yes': 'IGEN',
  'hall.taxes.no': 'NEM',
  'client.attr.auction.loading': 'Betöltés...',
  'client.attr.vehicle.photo_next': 'Következő kép',
  'client.attr.vehicle.photo_previous': 'Előző kép',
  'client.auction.bid': 'Licit',
  'client.auction.fees_extra': 'Kezelési költség ezen felül: {handlingFees} €',
  'client.auction.fees_included': 'Értékesítési költségekkel együtt',
  'client.auction.few_moments': 'néhány másodperc múlva',
  'client.auction.losing': 'Nem Öné a legjobb ajánlat',
  'client.auction.lost_text': 'Ön nem nyerte meg az árverést.',
  'client.auction.lot_closed': 'Lezárt tétel',
  'client.auction.next_auction': 'Következő árverés',
  'client.auction.opening_in': 'Az árverések megnyitása',
  'client.auction.register_to_bid': 'Regisztráljon, hogy licitálhasson',
  'client.auction.same_amount': 'Azonos ajánlat erre az összegre',
  'client.auction.seconds': 'Másodperc',
  'client.auction.sold': 'Elnyert',
  'client.auction.starting_price': 'Kikiáltási ár',
  'client.auction.vat_excluded': 'ÁFA Nélkül',
  'client.auction.winning': 'Öné a legjobb ajánlat',
  'client.auction.won_text1': 'Bravó! Ön megnyerte az árverést',
  'client.auction.won_text2': 'Kapcsolatba fogunk lépni Önnel.',
  'client.auction.you': 'Ön',
  'client.auction.salle': 'Előszoba',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Vissza a katalógushoz',
  'client.footer.footnote.one': 'Kezelési költség ezen felül: {handlingFees} € áfával',
  'client.footer.footnote.three': 'Codex hivatkozás a kibocsátáskor, vagy eredeti számla',
  'client.footer.footnote.two':
    'Prorata temporis árajánlat, vagy piaci ár, vagy becsült árajánlat, tájékoztató jellegű, nem a VPauto felelőssége',
  'client.footer.vehicle_link': 'A tétel részletes adatlapja',
  'client.header.live_from': 'Közvetlen kapcsolat',
  'client.header.logout': 'Kilépés',
  'client.popup.body.first': 'Ön a terembe ajánlattevő módban lép be.',
  'client.popup.body.second': 'A „Licitálás gomb megnyomása ajánlattételt jelent, és kötelezi Önt.',
  'client.popup.body.third':
    'Az árverések tartalmazzák az értékesítési költségeket (a kezelési költség ezen felül fizetendő).',
  'client.popup.confirm': 'Megértettem',
  'client.popup.header': 'Figyelem!',
  'client.vehicle.kilometers': 'Km',
  'client.vehicle.lot_number': 'N°',
  'client.vehicle.new_price': 'Új ár',
  'client.vehicle.observations': 'Észrevételek',
  'client.vehicle.options': 'Felszerelések',
  'client.vehicle.quote': 'Ár',
  'client.vehicle.vat': 'ÁFA',
  'client.vehicle.localisation': 'Helymeghatározás',
  'client.video.live_sale': 'Tekintse meg az élő akciót',
  'loading.app': 'Kérjük, várjon, a csatlakozás folyamatban van...',
  'loading.sale': 'Az eladás betöltése folyamatban van',
  'loading.vehicle': 'A jármű betöltése folyamatban van...',
  'next_sale.content.links.catalog': 'Az értékesítés katalógusa',
  'next_sale.content.links.sale_hours': 'Az értékesítések időpontjai',
  'next_sale.content.paused': '<bold>Az értékesítés</bold> szünetel',
  'next_sale.content.restored_in': '<bold>Folytatás:</bold> ',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {óra}
    =1 {óra}
    other {óra}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {perc}
    =1 {perc}
    other {perc}
  }`,
  'next_sale.footer.access': 'A <bold>belépés</bold> kiválasztása',
  'next_sale.footer.guide': 'A Live értékesítés <bold>használati útmutatója</bold> ',
  'next_sale.footer.main_site': '<bold>Vissza a </bold> VPautóhoz',
  'next_sale.footer.planning': 'A következő Live értékesítések <bold>Planning</bold> programja',
  'next_sale.footer.sound': 'Az értékesítés <bold>hallgatása</bold> ',
  'next_sale.footer.sound_instructions': 'Ez a funkció jelenleg nem elérhető.',
  'next_sale.header.live_from': '@:client.header.live_from',
}
