import React, { FC, useEffect, useState } from 'react'
import { Main, Header, Image, Heading, Box } from 'grommet'
import ResponsiveGrid from 'components/ResponsiveGrid'
import { useIntl } from 'react-intl'
import Carousel from '../components/Carousel'
import Details from './Details'
import Auctions from './Auctions'
import {
  AuctionQueryResult,
  CurrentSaleQueryResult,
  VehicleSaleQueryResult,
} from 'contexts/ApiContext/services/sale'

import icon from '../../assets/images/logo-live.png'
import styles from './Customer.module.scss'
import { useAuth } from 'contexts/AuthContext'
import InformationModal from './InformationModal'
import UserName from './UserName'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
}

const Customer: FC<OwnProps> = ({ vehicle, currentSale, auctions }) => {
  const { formatMessage } = useIntl()
  const { user } = useAuth()

  const [showInformationModal, setShowInformationModal] = useState(false)

  useEffect(() => {
    if (!user.isDemoUser) {
      setShowInformationModal(true)
    }
  }, [])

  return (
    <Main>
      <Header background="white">
        <UserName />
      </Header>
      <Header background="blue-400" pad={{ right: 'small', left: 'small' }}>
        <Image src={icon} />
        <Heading level={2} color="white" className={styles.header} margin="small">
          {`${formatMessage({ id: 'client.header.live_from' })} ${currentSale.room}`}
        </Heading>
      </Header>
      <ResponsiveGrid
        fill
        columns={{
          small: ['100%'],
          medium: ['1fr ', '1fr'],
        }}
        rows={{
          small: ['min-content', 'min-content', 'min-content'],
          medium: ['min-content', 'max-content'],
        }}
        areas={{
          xsmall: [
            { name: 'carousel', start: [0, 0], end: [0, 0] },
            { name: 'auctions', start: [0, 1], end: [0, 1] },
          ],
          medium: [
            { name: 'carousel', start: [0, 0], end: [0, 0] },
            { name: 'auctions', start: [1, 0], end: [1, 1] },
          ],
        }}
      >
        <Box gridArea="carousel">
          <Carousel vehicle={vehicle} />
          <Details vehicle={vehicle} sale={currentSale} />
        </Box>

        <Auctions
          currentSale={currentSale}
          vehicle={vehicle}
          auctions={auctions}
          gridArea="auctions"
        />
      </ResponsiveGrid>
      <InformationModal
        show={showInformationModal}
        setShow={setShowInformationModal}
      ></InformationModal>
    </Main>
  )
}

export default Customer
