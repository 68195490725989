import React, { useState, FC, useEffect } from 'react'
import { AuthInterface, User } from './types'
import { useMutationLogin } from '../ApiContext/services/auth'
import { useCookies } from 'react-cookie'
import { navigate } from '@reach/router'
import queryString from 'query-string'

export const initalValues = {
  isAuctioneer: false,
  isDemoUser: false,
  isForeigner: false,
  isOperator: false,
  isSalesperson: false,
  isLoggedIn: false,
  email: '',
  lang: '',
  name: '',
  token: '',
}

const AuthContext = React.createContext<AuthInterface>({
  user: initalValues,
})

export const AuthProvider: FC<{ setLocale: (lang: string) => void }> = ({
  setLocale,
  ...props
}) => {
  const [data, setData] = useState<User>(initalValues)

  const [{ userToken: cookieToken }, setToken] = useCookies(['userToken'])
  const { token: queryToken } = queryString.parse(window.location.search)

  const [mutateLogin] = useMutationLogin()

  const redirectLogin = (): void => {
    navigate(`${process.env.REACT_APP_API_URI}/security/login`)
  }

  useEffect(() => {
    async function login(token: string): Promise<void> {
      try {
        const user = (await mutateLogin({ token })) as User

        setToken('userToken', user.token)
        setLocale(user?.lang || 'fr')
        setData({ ...data, ...user, isLoggedIn: true })
      } catch {
        redirectLogin()
      }
    }

    if (queryToken || cookieToken) {
      login((queryToken as string) || (cookieToken as string))
    } else {
      redirectLogin()
    }
  }, [])

  return <AuthContext.Provider value={{ user: data }} {...props} />
}

export const useAuth = (): AuthInterface => React.useContext(AuthContext)
