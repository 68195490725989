import React, { FC } from 'react'
import styles from './Spinner.module.scss'

export interface SpinnerProps {
  size?: string
}

const Spinner: FC<SpinnerProps> = ({ size = '18px', ...props }) => {
  return (
    <div className={styles.spinner} {...props}>
      <div className={styles.bounce1} style={{ width: size, height: size }}></div>
      <div className={styles.bounce2} style={{ width: size, height: size }}></div>
      <div className={styles.bounce3} style={{ width: size, height: size }}></div>
    </div>
  )
}

export default Spinner
