import React, { FC } from 'react'
import { BoxProps, Box, Button, Text } from 'grommet'
import { FormSearch, FormPrevious } from 'grommet-icons'
import { useIntl } from 'react-intl'
import DetailsBox from './DetailsBox'
import Notes from './Notes'
import { CurrentSaleQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import Map from './Map'
import VideoStream from '../../../components/VideoStream'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  sale: CurrentSaleQueryResult
}

const Details: FC<OwnProps & BoxProps> = ({ vehicle, sale, ...props }) => {
  const { formatMessage } = useIntl()
  return (
    <Box {...props}>
      <Box background="gray-400" pad="xsmall" margin={{ vertical: 'xsmall' }}>
        <Text textAlign="center" size="medium">
          {vehicle.class} - <span dangerouslySetInnerHTML={{ __html: vehicle.color }} /> -{' '}
          <span dangerouslySetInnerHTML={{ __html: vehicle.energy }} /> -{' '}
          {formatMessage({ id: 'client.vehicle.vat' })} : {vehicle.vat ? 'Oui' : 'Non'}
        </Text>
      </Box>
      <Box pad="xsmall" style={{ paddingTop: 0 }}>
        <DetailsBox
          title={formatMessage({ id: 'client.vehicle.observations' })}
          content={vehicle.obsA.slice(0, 3)}
        >
          {vehicle.cityLocation && vehicle.zipCodeLocation && <Map vehicle={vehicle} sale={sale} />}
        </DetailsBox>
        <DetailsBox
          title={formatMessage({ id: 'client.vehicle.options' })}
          content={vehicle.options.slice(0, 6)}
        />
        <VideoStream />
        <Box direction="row" justify="between">
          <Button
            primary
            href={vehicle.listLink}
            target="_blank"
            label={
              <Box align="center" direction="row">
                <FormPrevious color="white" />
                <Text>{formatMessage({ id: 'client.footer.catalog' })}</Text>
              </Box>
            }
            color="blue-700"
            size="medium"
          />
          <Button
            primary
            href={vehicle.page_url}
            target="_blank"
            label={
              <Box align="center" direction="row">
                <FormSearch color="white" />
                <Text>{formatMessage({ id: 'client.footer.vehicle_link' })}</Text>
              </Box>
            }
            color="blue-700"
            size="medium"
          />
        </Box>
        <Notes sale={sale} />
      </Box>
    </Box>
  )
}
export default Details
