import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Heading, Box, Text } from 'grommet'
import { Refresh } from 'grommet-icons'

const Error: FC<RouteComponentProps> = () => {
  return (
    <>
      <Box direction="column" justify="center" align="center">
        <Heading level={1} size="large" textAlign="start">
          Oups ...
        </Heading>
        <Text size="xxlarge">
          Une erreur de connexion avec les serveurs vpauto.fr est survenue.
        </Text>
      </Box>
      <Box direction="row" gap="xxlarge" wrap justify="center" margin="large"></Box>
      <Box
        direction="column"
        gap="normal"
        justify="center"
        align="center"
        onClick={() => (window.location.href = '/')}
      >
        <Box direction="column" align="center" background="blue-500" width="400" round pad="large">
          <Refresh size="xlarge" color="white" />
          <Text size="xxlarge" color="white">
            Pour relancer la page cliquez ici.
          </Text>
        </Box>
      </Box>
    </>
  )
}

export default Error
