import React, { FC, useState } from 'react'
import Biding from './component'
import {
  VehicleSaleQueryResult,
  AuctionQueryResult,
  CurrentSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import { useApi } from 'contexts/ApiContext'
import Timer from 'services/timer'

export interface Error {
  message: string
  isError: boolean
}

const initialError = { message: '', isError: false }

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
  currentSale: CurrentSaleQueryResult
  tick: number | null
}

const BidingContainer: FC<OwnProps> = ({ vehicle, auctions, currentSale, tick }) => {
  const { sale } = useApi()

  const [hasBidded, setHasBidded] = useState(false)
  const [mutateSendBid, { isLoading: biding }] = sale.useSendBid()
  const [error, setError] = useState<Error>(initialError)

  const sendBid = (amount: number): void => {
    setError(initialError)
    mutateSendBid(
      {
        saleId: currentSale.id,
        vehicleId: vehicle.id,
        data: {
          bid: amount,
          second: Timer.getInstance().time / 1000,
          timestamp: new Date().getTime(),
        },
      },
      {
        onSuccess(data) {
          if (data.status === 'error') {
            setError({ message: data.message, isError: true })
          } else {
            setHasBidded(true)
          }
        },
      }
    )
  }

  return (
    <Biding
      vehicle={vehicle}
      currentSale={currentSale}
      auctions={auctions}
      sendBid={sendBid}
      hasBidded={hasBidded}
      biding={biding}
      error={error}
      tick={tick}
    />
  )
}

export default BidingContainer
