export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'LIVE SALE',
  'hall.lotNumber.label': 'N°{number}',
  'hall.newPrice': 'New price* : {price}',
  'hall.taxes.label': 'VAT',
  'hall.taxes.yes': 'YES',
  'hall.taxes.no': 'NON',
  'client.attr.auction.loading': 'Loading...',
  'client.attr.vehicle.photo_next': 'Next image',
  'client.attr.vehicle.photo_previous': 'Previous image',
  'client.auction.bid': 'Bid',
  'client.auction.fees_extra': 'Additional handling fees: € {handlingFees}',
  'client.auction.fees_included': 'Sales fees included',
  'client.auction.few_moments': 'in a few moments',
  'client.auction.losing': 'You are losing',
  'client.auction.lost_text': 'You did not win the auction.',
  'client.auction.lot_closed': 'Lot closed',
  'client.auction.next_auction': 'Next auction',
  'client.auction.opening_in': 'Opening of the bidding',
  'client.auction.register_to_bid': 'Register so you can bid',
  'client.auction.same_amount': 'The bid is the same at the moment',
  'client.auction.seconds': 'Second(s)',
  'client.auction.sold': 'Sold',
  'client.auction.starting_price': 'Starting price',
  'client.auction.vat_excluded': 'VAT Excluded',
  'client.auction.winning': 'You are winning',
  'client.auction.won_text1': 'Congratulations! You won the auction',
  'client.auction.won_text2': 'We will contact you.',
  'client.auction.you': 'You',
  'client.auction.salle': 'Room',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.auction.interenchères': 'Web',
  'client.footer.catalog': 'Return to the catalogue',
  'client.footer.footnote.one': 'Additional handling fees: € {handlingFees} inc VAT',
  'client.footer.footnote.three': 'Codex reference on the release date or original invoice',
  'client.footer.footnote.two':
    'Prorata temporis, or market price, or estimated quotation, given as an indication, not engaging the responsibility of VPauto',
  'client.footer.vehicle_link': 'Lot details',
  'client.header.live_from': 'Live from',
  'client.header.logout': 'Sign out',
  'client.popup.body.first': 'You are entering the salesroom in bidder mode.',
  'client.popup.body.second': 'Clicking the Bid button binds you to the bid.',
  'client.popup.body.third':
    'The bids are made including sales fees (handling expenses are additional).',
  'client.popup.confirm': 'Understood',
  'client.popup.header': 'Note',
  'client.vehicle.kilometers': 'km',
  'client.vehicle.lot_number': 'N°',
  'client.vehicle.new_price': 'New price',
  'client.vehicle.observations': 'Observation(s)',
  'client.vehicle.options': 'Options',
  'client.vehicle.quote': 'Quote',
  'client.vehicle.vat': 'VAT',
  'client.vehicle.localisation': 'Location',
  'client.video.live_sale': 'See the live sale',
  'loading.app': 'Please wait, signing in...',
  'loading.sale': 'Loading the sale...',
  'loading.vehicle': 'Loading the vehicle...',
  'next_sale.content.links.catalog': 'Sale catalogue',
  'next_sale.content.links.sale_hours': 'Sale hours',
  'next_sale.content.paused': '<bold>Sale</bold> paused',
  'next_sale.content.restored_in': '<bold>Resumes</bold> in',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {hour}
    =1 {hour}
    other {hours}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minute}
    =1 {minute}
    other {minutes}
  }`,
  'next_sale.footer.access': 'Choose your <bold>access</bold>',
  'next_sale.footer.guide': '<bold>Live</bold> mode',
  'next_sale.footer.main_site': '<bold>Return</bold> to VPauto',
  'next_sale.footer.planning': '<bold>Planning</bold> for the next Live',
  'next_sale.footer.sound': '<bold>Listen</bold> to the sale',
  'next_sale.footer.sound_instructions': 'This option is not currently available.',
  'next_sale.header.live_from': '@:client.header.live_from',
}
