import React, { FC, useState } from 'react'
import { Box, Button, ThemeContext } from 'grommet'
import { Power, CaretNext, ChapterNext, ChapterPrevious, CaretPrevious } from 'grommet-icons'
import { useIntl } from 'react-intl'
import ChangeLot from './ChangeLot'
import CloseSale from './CloseSale'
import styles from './Toolbox.module.scss'
import {
  VehicleSaleQueryResult,
  VehicleOrderSaleQueryResult,
  CurrentSaleQueryResult,
} from 'contexts/ApiContext/services/sale'

const buttonSize = '18px'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  nextVehicle: Function
  prevVehicle: Function
  firstVehicle: Function
  lastVehicle: Function
  changeVehicle: Function
  vehiclesOrderSale: VehicleOrderSaleQueryResult[]
  currentSale: CurrentSaleQueryResult
}

const Toolbox: FC<OwnProps> = ({
  vehicle,
  nextVehicle,
  prevVehicle,
  firstVehicle,
  lastVehicle,
  vehiclesOrderSale,
  changeVehicle,
  currentSale,
}) => {
  const { formatMessage } = useIntl()

  const [showChangeLot, setShowChangeLot] = useState(false)
  const [showCloseSale, setShowCloseSale] = useState(false)

  const goToOpenedVehicule = (): void => {
    const openedVehicle = currentSale.openedVehicle
    if (vehicle.id === openedVehicle) {
      return
    }
    openedVehicle ? changeVehicle(openedVehicle) : setShowChangeLot(true)
  }

  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
      }}
    >
      <Box
        background="white"
        round="xxsmall"
        pad="xsmall"
        direction="row"
        gap="xsmall"
        justify="center"
        margin={{ bottom: 'xsmall' }}
        className={styles.containerLot}
        style={{ minHeight: '60px' }}
        wrap
      >
        <Button
          data-cy="button-close-sale"
          a11yTitle={formatMessage({ id: 'lot.toolbox.shutdown.a11yLabel' })}
          gap="none"
          primary
          color="red-600"
          label={<Power size={buttonSize} color="white" />}
          disabled={vehicle.lotOpen && currentSale.open}
          onClick={() => setShowCloseSale(true)}
        ></Button>
        <Button
          data-cy="button-first-lot"
          a11yTitle={formatMessage({ id: 'lot.toolbox.first.a11yLabel' })}
          gap="none"
          primary
          color="blue-400"
          style={{ padding: '4px 15px' }}
          label={<ChapterPrevious size={buttonSize} color="white" />}
          onClick={() => firstVehicle()}
        ></Button>
        <Button
          data-cy="lot-precedent"
          a11yTitle={formatMessage({ id: 'lot.toolbox.previous.a11yLabel' })}
          gap="none"
          primary
          color="blue-400"
          style={{ padding: '4px 15px' }}
          label={<CaretPrevious size={buttonSize} color="white" />}
          onClick={() => prevVehicle()}
        ></Button>
        <Button
          data-cy="lot-number"
          primary
          color="blue-600"
          label={formatMessage(
            { id: 'lot.toolbox.label' },
            {
              number: `${vehicle.lot}${vehicle.lotComplement ? ` ${vehicle.lotComplement}` : ''}`,
            }
          )}
          className={styles.lotNumber}
          onClick={() => goToOpenedVehicule()}
        ></Button>
        <Button
          data-cy="lot-suivant"
          a11yTitle={formatMessage({ id: 'lot.toolbox.next.a11yLabel' })}
          gap="none"
          primary
          color="blue-400"
          style={{ padding: '4px 15px' }}
          label={<CaretNext size={buttonSize} color="white" />}
          onClick={() => nextVehicle()}
        ></Button>
        <Button
          data-cy="button-last-lot"
          a11yTitle={formatMessage({ id: 'lot.toolbox.last.a11yLabel' })}
          gap="none"
          primary
          color="blue-400"
          style={{ padding: '4px 15px' }}
          label={<ChapterNext size={buttonSize} color="white" />}
          onClick={() => lastVehicle()}
        ></Button>
      </Box>
      <ChangeLot
        vehiclesOrderSale={vehiclesOrderSale}
        changeVehicle={changeVehicle}
        show={showChangeLot}
        setShow={setShowChangeLot}
      />
      <CloseSale show={showCloseSale} setShow={setShowCloseSale} currentSale={currentSale} />
    </ThemeContext.Extend>
  )
}

export default Toolbox
