import React, { FC, useContext } from 'react'
import { Grid, GridProps, ResponsiveContext } from 'grommet'

interface ResponsiveGridProps extends Omit<GridProps, 'areas' | 'rows' | 'columns'> {
  areas?: {
    [key: string]: {
      name?: string
      start: number[]
      end: number[]
    }[]
  }
  rows?: { [key: string]: string[] }
  columns?: { [key: string]: string[] }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const getLargestProp = (
  size: string,
  properties: { [key: string]: any[] } = {}
): any[] | undefined => {
  if (Object.keys(properties).includes(size)) {
    return properties[size]
  }
  const fallbacks = new Map<string, string[]>()
  fallbacks.set('xsmall', [])
  fallbacks.set('small', ['xsmall'])
  fallbacks.set('medium', ['small', 'xsmall'])
  fallbacks.set('large', ['medium', 'small', 'xsmall'])
  fallbacks.set('xlarge', ['large', 'medium', 'small', 'xsmall'])

  const fallbackSizes = fallbacks.get(size) || []
  for (const fallbackSize of fallbackSizes) {
    if (properties[fallbackSize]) {
      return properties[fallbackSize]
    }
  }
  return
}
/* eslint-enable @typescript-eslint/no-explicit-any */
const ResponsiveGrid: FC<ResponsiveGridProps> = ({
  children,
  areas = {},
  columns = {},
  rows = {},
  ...props
}) => {
  const size = useContext(ResponsiveContext)
  return (
    <Grid
      areas={getLargestProp(size, areas)}
      columns={getLargestProp(size, columns)}
      rows={getLargestProp(size, rows)}
      {...props}
    >
      {children}
    </Grid>
  )
}

export default ResponsiveGrid
