/* eslint @typescript-eslint/no-var-requires: "off" */
import { queryCache } from 'react-query'
import { VehicleSaleQueryResult, CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'
import Timer from 'services/timer'
import {
  getUsersList,
  UserInfoResult,
  UsersLoggedListQueryResult,
} from 'contexts/ApiContext/services/users'
import { LoginSocketResponse, VehicleSocketResponse } from './types'
import useSound from 'hooks/useSound'
import { User } from 'contexts/AuthContext/types'
const bipSound = require('../../assets/audio/auction-notif.mp3')

export enum EVENTS {
  SALE = 'sale',
  USER = 'user',
  VEHICLE = 'vehicle',
  LOGIN = 'login',
}

const pagesWithSound = ['/operator']
const salespersonPages = ['/salesperson']

const loadListeners = (socket: SocketIOClient.Socket | null, locale: string): void => {
  socket?.on(EVENTS.VEHICLE, (vehicleResponse: Partial<VehicleSocketResponse>) => {
    const currentVehicle = queryCache.getQueryData('currentVehicle') as VehicleSaleQueryResult
    const { [locale]: vehicle } = vehicleResponse
    const serverTimestamp = vehicleResponse.serverTimestamp
    const sound = useSound(bipSound)

    if (currentVehicle?.id !== vehicle.id) {
      if (!vehicle.maker) {
        return
      }
    }

    // update the auction open
    if (vehicle.closedAt && vehicle.openedAt) {
      vehicle.auctionOpen = vehicle.closedAt > vehicle.openedAt
    } else if (vehicle.closedAt && !currentVehicle.auctionOpen) {
      vehicle.auctionOpen = vehicle.closedAt > currentVehicle.openedAt
    }

    // Adding new auction and restart timer
    if (vehicle.lastAuctions) {
      queryCache.setQueryData(['auctions', vehicle.id], vehicle.lastAuctions)
      if (pagesWithSound.includes(window.location.pathname) && vehicle.lastAuctions?.length > 0) {
        sound.play()
      }
      Timer.getInstance().stop()
    }

    queryCache.setQueryData('currentVehicle', { ...currentVehicle, ...vehicle, serverTimestamp })
  })

  socket?.on(EVENTS.SALE, (sale: Partial<CurrentSaleQueryResult>) => {
    const currentSale = queryCache.getQueryData('currentSale') as CurrentSaleQueryResult

    queryCache.setQueryData('currentSale', { ...currentSale, ...sale })
  })

  socket?.on(EVENTS.USER, async (wsUser: Partial<UserInfoResult>) => {
    const loggedUser = queryCache.getQueryData('loggedUser') as User

    if (!(loggedUser?.isSalesperson && salespersonPages.includes(window.location.pathname))) {
      return
    }

    if (!wsUser.id) {
      return
    }
    const usersList = await getUsersList()
    const currentUsersList = queryCache.getQueryData(
      'usersLoggedList'
    ) as UsersLoggedListQueryResult
    queryCache.setQueryData<UsersLoggedListQueryResult>('usersLoggedList', {
      ...currentUsersList,
      ...usersList,
    })
  })

  socket?.on(EVENTS.LOGIN, async (connectedNumber: LoginSocketResponse) => {
    queryCache.setQueryData<LoginSocketResponse>('usersConnected', {
      ...connectedNumber,
    })
    queryCache.refetchQueries('usersConnected')
  })
}

export default loadListeners
