import { RouteComponentProps } from '@reach/router'
import Loading from 'app/Loading'
import { useApi } from 'contexts/ApiContext'
import useLastOpenedVehicle from 'hooks/useLastOpenedVehicule'
import React, { FC } from 'react'
import Slider from './component'

const SliderContainer: FC<RouteComponentProps> = () => {
  const { sale } = useApi()

  const { data: currentSale } = sale.useCurrentSale()

  const lastOpenedVehicle = useLastOpenedVehicle(currentSale)

  const { data: vehicle } = sale.useVehiclesSale(
    currentSale?.id,
    currentSale?.openedVehicle || lastOpenedVehicle,
    {
      enabled: currentSale,
    }
  )

  if (!vehicle) {
    return <Loading />
  }

  return <Slider vehicle={vehicle} />
}
export default SliderContainer
