import React, { FC } from 'react'
import { Box, Text } from 'grommet'
import { VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { useIntl } from 'react-intl'
import { Currency } from 'components/Number'
import styles from './Waiting.module.scss'
import Spinner from 'components/Spinner'
import { calculateVatFreePrice } from 'services/helpers'

const Waiting: FC<{ vehicle: VehicleSaleQueryResult; paused?: boolean }> = ({
  vehicle,
  paused = false,
  ...props
}) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Box align="center" margin={{ vertical: 'small' }} {...props}>
        <Text textAlign="center" weight="bold" size="45px" className={styles.uppercase}>
          {formatMessage({ id: 'client.auction.opening_in' })}
        </Text>
        <Text textAlign="center" size="45px">
          {formatMessage({ id: 'client.auction.few_moments' })}
        </Text>
        <Spinner size="30px" />
        {!paused && (
          <Text textAlign="center" size="55px" color="gray-900" margin={{ top: 'xlarge' }}>
            {formatMessage({ id: 'client.auction.starting_price' })}{' '}
            <Currency
              className={styles.price}
              margin={{ left: 'xlarge' }}
              value={vehicle.startingPrice}
              size="55px"
            />
          </Text>
        )}
        {['CTTE', 'CAM'].includes(vehicle.class) && vehicle.vat === true && !paused && (
          <Text margin={{ top: 'large' }} size="30px">
            {formatMessage({ id: 'client.auction.vat_excluded' })} :{' '}
            {calculateVatFreePrice(vehicle.startingPrice, vehicle.vatAmount)} €
          </Text>
        )}
      </Box>
    </>
  )
}

export default Waiting
