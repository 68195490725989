import { Currency } from 'components/Number'
import { AuctionQueryResult } from 'contexts/ApiContext/services/sale'
import { DataTableProps, Text } from 'grommet'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

const Header: FC = ({ children, ...props }) => (
  <Text weight="bold" {...props}>
    {children}
  </Text>
)

export const useColumns = (isAuctionner: boolean): DataTableProps['columns'] => {
  const { formatMessage } = useIntl()

  if (isAuctionner) {
    return [
      {
        property: 'type',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.origin' })}</Header>,
      },
      {
        property: 'user.type',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.user.type' })}</Header>,
        render: (auction: AuctionQueryResult) =>
          auction.user.type
            ? formatMessage({ id: `operator.auctions.user.type.${auction.user.type}` })
            : '',
      },
      {
        property: 'user.extendedName',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.buyer' })}</Header>,
      },

      {
        property: 'user.society',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.company' })}</Header>,
      },
      {
        property: 'user.country',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.user.country' })}</Header>,
      },
      {
        property: 'amount',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.amount' })}</Header>,
        render: function Amount(datum: AuctionQueryResult) {
          return (
            <Text weight="bold">
              <Currency value={datum.amount} />
            </Text>
          )
        },
      },
      {
        property: 'biggestPI',
        size: 'small',
        header: <Header>{formatMessage({ id: 'operator.auctions.maxBid' })}</Header>,
        render: function Amount(datum: AuctionQueryResult) {
          return <Currency value={datum.biggestPI} />
        },
      },
    ]
  }

  return [
    {
      property: 'type',
      size: 'small',
      header: <Header>{formatMessage({ id: 'operator.auctions.origin' })}</Header>,
    },
    {
      property: 'user.extendedName',
      size: 'small',
      header: <Header>{formatMessage({ id: 'operator.auctions.buyer' })}</Header>,
    },

    {
      property: 'user.society',
      size: 'small',
      header: <Header>{formatMessage({ id: 'operator.auctions.company' })}</Header>,
    },
    {
      property: 'amountHf',
      size: 'small',
      header: <Header>{formatMessage({ id: 'operator.auctions.amountHF' })}</Header>,
      render: function HfAmount(datum: AuctionQueryResult) {
        return <Currency value={datum.amountHf} />
      },
    },
    {
      property: 'amount',
      size: 'small',
      header: <Header>{formatMessage({ id: 'operator.auctions.amount' })}</Header>,
      render: function Amount(datum: AuctionQueryResult) {
        return (
          <Text weight="bold">
            <Currency value={datum.amount} />
          </Text>
        )
      },
    },
    {
      property: 'biggestPI',
      size: 'small',
      header: <Header>{formatMessage({ id: 'operator.auctions.maxBid' })}</Header>,
      render: function Amount(datum: AuctionQueryResult) {
        return <Currency value={datum.biggestPI} />
      },
    },
  ]
}
