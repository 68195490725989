import { useLocation } from '@reach/router'
import { AuctionQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { Box, BoxProps, DataTable, ResponsiveContext, Text, ThemeContext } from 'grommet'
import React, { FC, useContext, useState } from 'react'
import { CurrentSaleQueryResult } from '../../../contexts/ApiContext/services/sale'
import CloseAuction from './CloseAuction'
import { useColumns } from './columns'
import { extendedOperatorTheme } from 'app/Operator/component'
import styles from './AuctionsTable.module.scss'

interface OwnProps extends BoxProps {
  auctions: AuctionQueryResult[] | []
  vehicle: VehicleSaleQueryResult
  currentSale: CurrentSaleQueryResult
}

const AuctionsTable: FC<OwnProps> = ({ auctions, vehicle, currentSale, children, ...props }) => {
  const [showCloseAuction, setShowCloseAuction] = useState(false)

  const size = useContext(ResponsiveContext)
  const isMobile = ['small', 'medium'].includes(size)

  const lastAuctions = isMobile ? auctions.slice(0, 6) : auctions

  const location = useLocation()
  const isAuctionner = location.pathname.includes('auctioneer')
  const extendedAuctions = lastAuctions.map((auction) => {
    return {
      ...auction,
      user: {
        ...auction.user,
        extendedName: auction.user.email ? `${auction.user.name} (${auction.user.email})` : null,
      },
    }
  })

  return (
    <ThemeContext.Extend
      value={{
        ...extendedOperatorTheme,
      }}
    >
      <div>
        <Box background="blue-500" pad="xsmall" direction="row" justify="center">
          <Text size="small" alignSelf="center" color="white" margin="xxsmall">
            {currentSale.name}
          </Text>
          {currentSale.open ? (
            <Box
              background="green-500"
              align="center"
              direction="row"
              pad={{ horizontal: 'xsmall' }}
            >
              <Text size="small" alignSelf="center" color="white">
                ouverte
              </Text>
            </Box>
          ) : (
            <Box background="red-700" align="center" direction="row" pad={{ horizontal: 'xsmall' }}>
              <Text size="small" alignSelf="center" color="white" data-cy="auctions-info-close">
                fermée
              </Text>
            </Box>
          )}
          {currentSale.paused && (
            <Box
              background="orange-600"
              align="center"
              direction="row"
              pad={{ horizontal: 'xsmall' }}
              margin={{ left: 'small' }}
            >
              <Text size="small" alignSelf="center" color="white">
                pause
              </Text>
            </Box>
          )}
        </Box>
        <Box pad="small" className={styles.table}>
          <DataTable
            onClickRow={(event: { datum?: {} }) => setShowCloseAuction(true)}
            border
            id="auctions-table"
            data-cy="auctions-table"
            primaryKey="id"
            background={{
              header: 'gray-200',
            }}
            columns={useColumns(isAuctionner)}
            data={extendedAuctions}
          />
        </Box>
        <CloseAuction
          show={showCloseAuction}
          setShow={setShowCloseAuction}
          vehicle={vehicle}
          currentSale={currentSale}
        ></CloseAuction>
      </div>
    </ThemeContext.Extend>
  )
}

export default AuctionsTable
