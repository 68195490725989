import React, { FC } from 'react'
import { useAuth, initalValues } from '../AuthContext'
import { User } from '../AuthContext/types'

const UserContext = React.createContext<User>(initalValues)

export const UserProvider: FC = (props) => {
  return <UserContext.Provider value={useAuth().user ?? null} {...props} />
}

export const useUser = (): User => {
  const user = React.useContext(UserContext)
  if (!user.isLoggedIn) {
    throw new Error('useUser: You are trying to retrive user data but you are not logged in yet')
  }
  return user
}
