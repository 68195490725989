import { CurrentSaleQueryResult, VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { Box, BoxProps, Button, Grid, Text, TextInput, ThemeContext } from 'grommet'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Timer, { Status } from 'services/timer'
import { AuctionQueryResult } from '../../../contexts/ApiContext/services/sale'
import CloseLot from './CloseLot/component'
import PauseLot from './PauseLot'
import ChangePrice from './ChangePrice'
import { extendedOperatorTheme } from '../component'

interface OwnProps extends BoxProps {
  vehicle: VehicleSaleQueryResult
  currentSale: CurrentSaleQueryResult
  auctions: AuctionQueryResult[] | []
  openLot: Function
  closeLot: Function
  openAuction: Function
  sendBid: Function
  pauseLot: Function
  restoreLot: Function
  salePaused: boolean
  awardedButton: Function
  changePrice: Function
  changeDefaultPitch: Function
  changeStartTimer: Function
  changeAuctionDuration: Function
  changeBidPitch: Function
  error: string
}

const Dashboard: FC<OwnProps> = ({
  vehicle,
  currentSale,
  auctions,
  openLot,
  closeLot,
  openAuction,
  children,
  sendBid,
  pauseLot,
  restoreLot,
  salePaused,
  awardedButton,
  changePrice,
  changeDefaultPitch,
  changeStartTimer,
  changeAuctionDuration,
  changeBidPitch,
  error,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [tick, setTick] = useState<number | null>(null)

  const [showCloseLotModal, setShowCloseLotModal] = useState(false)
  const [showPauseLotModal, setShowPauseLotModal] = useState(false)
  const [newBidPitch, setNewBidPitch] = useState<number>(vehicle.pricePitch)
  const [newDefaultPitch, setNewDefaultPitch] = useState<number>(vehicle.defaultPitch)
  const [newStartTimer, setNewStartTimer] = useState<number>(vehicle.startingDuration)
  const [newAuctionDuration, setAuctionDuration] = useState<number>(vehicle.auctionDuration)

  const [showChangePriceModal, setShowChangePriceModal] = useState(false)

  useEffect(() => {
    Timer.getInstance().on('tick', (ms: number) => {
      const timeLeft = Math.ceil(ms / 1000)
      setTick(timeLeft < 0 ? null : timeLeft)
    })
  }, [])

  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: 'white',
        },
        ...extendedOperatorTheme,
      }}
    >
      <Box pad="xsmall" {...props} data-cy="operator-dashboard">
        {error && (
          <Text weight="bold" color="red-500" margin={{ bottom: '10px' }}>
            {error}
          </Text>
        )}
        <Grid
          gap="xsmall"
          columns={['fit', 'fit', 'fit', 'fit', 'fit']}
          rows={['75px', '75px', '75px', '75px', '75px']}
          areas={[
            { name: 'openLot', start: [0, 0], end: [1, 0] },
            { name: 'openAuctions', start: [1, 0], end: [1, 0] },
            { name: 'pause', start: [2, 0], end: [2, 0] },
            { name: 'close', start: [3, 0], end: [3, 0] },

            { name: 'step50', start: [0, 1], end: [1, 1] },
            { name: 'step100', start: [1, 1], end: [1, 1] },
            { name: 'step200', start: [2, 1], end: [2, 1] },
            { name: 'step500', start: [3, 1], end: [3, 1] },

            { name: 'end', start: [0, 2], end: [0, 2] },
            { name: 'defaultPitch', start: [1, 2], end: [1, 2] },
            { name: 'bidPitch', start: [2, 2], end: [2, 2] },
            { name: 'editPrice', start: [3, 2], end: [3, 2] },
            { name: 'interauctionBid', start: [0, 3], end: [1, 3] },
            { name: 'startTimer', start: [2, 3], end: [2, 3] },
            { name: 'auctionTimer', start: [3, 3], end: [3, 3] },
            { name: 'hallBid', start: [0, 4], end: [3, 4] },
          ]}
        >
          <Button
            primary
            gridArea="openLot"
            label={formatMessage({ id: 'operator.dashboard.openLot' })}
            color="blue-300"
            disabled={vehicle.lotOpen || currentSale.paused}
            data-cy="open-lot-button"
            onClick={() => {
              openLot()
            }}
          />
          <Button
            primary
            gridArea="openAuctions"
            data-cy="open-auction-btn"
            label={formatMessage({ id: 'operator.dashboard.openAuctions' })}
            disabled={
              !vehicle.lotOpen || vehicle.auctionOpen || vehicle.pausedAt || currentSale.paused
            }
            color="green-600"
            onClick={() => openAuction()}
          />
          <Button
            primary
            gridArea="pause"
            data-cy="pause-sale-btn"
            label={
              salePaused
                ? formatMessage({ id: 'operator.dashboard.restart' })
                : formatMessage({ id: 'operator.dashboard.pause' })
            }
            color="orange-600"
            onClick={salePaused ? () => restoreLot() : () => setShowPauseLotModal(true)}
          />
          <Button
            primary
            gridArea="close"
            data-cy="close-lot-btn"
            label={formatMessage({ id: 'operator.dashboard.close' })}
            disabled={!vehicle.lotOpen}
            color="red-700"
            onClick={() => {
              auctions?.length !== 0 && vehicle.reservePrice > auctions?.[0].amount
                ? setShowCloseLotModal(true)
                : closeLot()
            }}
          />

          <Button
            primary
            gridArea="step50"
            label="50"
            color="blue-300"
            disabled={currentSale.bidIncrement === 50}
            data-cy="change-step-50"
            onClick={() => {
              changeBidPitch(50)
            }}
          />
          <Button
            primary
            gridArea="step100"
            label="100"
            color="blue-300"
            disabled={currentSale.bidIncrement === 100}
            data-cy="change-step-100"
            onClick={() => {
              changeBidPitch(100)
            }}
          />

          <Button
            primary
            gridArea="step200"
            label="200"
            color="blue-300"
            disabled={currentSale.bidIncrement === 200}
            data-cy="change-step-200"
            onClick={() => {
              changeBidPitch(200)
            }}
          />
          <Button
            primary
            gridArea="step500"
            label="500"
            color="blue-300"
            disabled={currentSale.bidIncrement === 500}
            data-cy="change-step-500"
            onClick={() => {
              changeBidPitch(500)
            }}
          />

          <Box
            gridArea="end"
            align="center"
            justify="center"
            round="xxsmall"
            border={{ color: 'red-200', size: 'small' }}
          >
            {
              <Text color="red-400" size="xlarge" data-cy="timer">
                {Timer.getInstance().status === Status.RUNNING && vehicle.auctionOpen
                  ? tick && tick
                  : formatMessage({ id: 'operator.dashboard.end' })}
              </Text>
            }
          </Box>

          <Box gridArea="defaultPitch" direction="column" justify="between" pad="xxsmall">
            <Text>{formatMessage({ id: 'operator.dashboard.defaultPitch' })}</Text>
            <Box direction="row">
              <TextInput
                Data-cy="default-pitch"
                value={newDefaultPitch}
                type="number"
                onChange={(event) => {
                  setNewDefaultPitch(Number(event.target.value))
                }}
              />
              <Button
                primary
                label={formatMessage({ id: 'operator.dashboard.ok' })}
                color="gray-500"
                data-cy="update-default-pitch"
                onClick={() => changeDefaultPitch(newDefaultPitch)}
              />
            </Box>
          </Box>
          <Box gridArea="bidPitch" direction="column" justify="between" pad="xxsmall">
            <Box>
              <Text>{formatMessage({ id: 'operator.dashboard.bidPitch' })}</Text>
              <Box direction="row">
                <TextInput
                  Data-cy="change-step-btn"
                  value={newBidPitch}
                  type="number"
                  onChange={(event) => {
                    setNewBidPitch(Number(event.target.value))
                  }}
                />
                <Button
                  primary
                  label={formatMessage({ id: 'operator.dashboard.ok' })}
                  color="gray-500"
                  data-cy="update-price-pitch"
                  onClick={() => changeBidPitch(newBidPitch)}
                />
              </Box>
            </Box>
          </Box>
          <Button
            primary
            data-cy="edit-price-btn"
            gridArea="editPrice"
            disabled={vehicle.auctionOpen}
            label={formatMessage({ id: 'operator.dashboard.editPrice' })}
            color="orange-400"
            onClick={() => setShowChangePriceModal(true)}
          />
          <Button
            primary
            gridArea="interauctionBid"
            label={formatMessage({ id: 'operator.dashboard.interauctionBid' })}
            disabled={!vehicle.lotOpen}
            onClick={() => sendBid('Interenchères')}
            color="blue-700"
            data-cy="interauction-bid-btn"
          />
          <Box gridArea="startTimer" direction="column" justify="between" pad="xxsmall">
            <Text>{formatMessage({ id: 'operator.dashboard.startTimer' })}</Text>
            <Box direction="row">
              <TextInput
                data-cy="start-timer-input"
                type="number"
                value={newStartTimer}
                onChange={(event) => {
                  setNewStartTimer(Number(event.target.value))
                }}
              />
              <Button
                primary
                label={formatMessage({ id: 'operator.dashboard.ok' })}
                color="gray-500"
                data-cy="start-timer-btn"
                onClick={() => changeStartTimer(newStartTimer)}
              />
            </Box>
          </Box>
          <Box gridArea="auctionTimer" direction="column" justify="between" pad="xxsmall">
            <Text>{formatMessage({ id: 'operator.dashboard.auctionTimer' })}</Text>
            <Box direction="row">
              <TextInput
                data-cy="auction-timer-input"
                type="number"
                value={newAuctionDuration}
                onChange={(event) => {
                  setAuctionDuration(Number(event.target.value))
                }}
              />
              <Button
                primary
                label={formatMessage({ id: 'operator.dashboard.ok' })}
                color="gray-500"
                data-cy="auction-timer-btn"
                onClick={() => changeAuctionDuration(newAuctionDuration)}
              />
            </Box>
          </Box>
          <Button
            primary
            gridArea="hallBid"
            label={formatMessage({ id: 'operator.dashboard.hallBid' })}
            disabled={!vehicle.lotOpen}
            color="blue-500"
            data-cy="hall-bid-btn"
            onClick={() => {
              sendBid()
            }}
          />
        </Grid>
        <CloseLot
          show={showCloseLotModal}
          setShow={setShowCloseLotModal}
          closeLotButton={closeLot}
          awardedButton={awardedButton}
        ></CloseLot>
        <PauseLot
          show={showPauseLotModal}
          setShow={setShowPauseLotModal}
          selectTime={pauseLot}
        ></PauseLot>
        <ChangePrice
          show={showChangePriceModal}
          setShow={setShowChangePriceModal}
          vehicle={vehicle}
          changePriceButton={changePrice}
        ></ChangePrice>
      </Box>
    </ThemeContext.Extend>
  )
}

export default Dashboard
