import cx from 'classnames'
import { VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { useAuth } from 'contexts/AuthContext'
import { Box, BoxProps, Stack, Text, Image as GrommetImage, Button } from 'grommet'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Languages } from 'translations'
import styles from './Carousel.module.scss'
import { Previous, Next } from 'grommet-icons'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  screenSlider?: boolean
  autoplay?: boolean
}

const Carousel: FC<OwnProps & BoxProps> = ({
  vehicle,
  screenSlider = false,
  autoplay = false,
  ...props
}) => {
  const { formatMessage } = useIntl()

  const { user } = useAuth()
  const userLang = user.lang ? user.lang : 'en'
  const autoPlayInterval = 5000
  const images = vehicle.photos ? vehicle.photos : []
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [loadedImages, setLoadedImages] = React.useState([0])
  const [imageLoaded, setImageLoaded] = React.useState(false) // Track if the image is loaded
  const autoPlayRef = React.useRef<() => Promise<void>>()

  React.useEffect(() => {
    if (images?.length > 0) {
      setLoadedImages([0])
      setActiveIndex(0)
    }
  }, [images])

  React.useEffect(() => {
    if (autoplay) {
      autoPlayRef.current = handleNextImage
      const play = (): void => {
        autoPlayRef?.current && autoPlayRef.current()
      }

      const interval = setInterval(play, autoPlayInterval)
      return () => clearInterval(interval)
    }
  }, [activeIndex, autoPlayInterval, autoplay])

  const preloadImage = (url: string): Promise<void> => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => resolve()
      img.src = url
    })
  }

  const handleNextImage = async (): Promise<void> => {
    const newIndex = (activeIndex + 1) % images.length
    if (!loadedImages.includes(newIndex)) {
      await preloadImage(images[newIndex])
      setLoadedImages((prevLoadedImages) => [...prevLoadedImages, newIndex])
    }
    setActiveIndex(newIndex)
    setImageLoaded(false)
  }

  const handlePreviousImage = async (): Promise<void> => {
    const newIndex = (activeIndex - 1 + images.length) % images.length
    if (!loadedImages.includes(newIndex)) {
      await preloadImage(images[newIndex])
      setLoadedImages((prevLoadedImages) => [...prevLoadedImages, newIndex])
    }
    setActiveIndex(newIndex)
    setImageLoaded(false)
  }

  const handleImageLoad = (): void => {
    setImageLoaded(true)
  }

  return (
    <Box {...props} className={screenSlider ? '' : styles.carousel}>
      <Stack anchor="top-left">
        <Box height={screenSlider ? '100vh' : 'medium'} width="100%">
          <div className={styles.carouselContainer}>
            <Button
              className={cx(styles.buttons, styles.previous)}
              onClick={handlePreviousImage}
              icon={<Previous />}
              aria-label="Previous"
            />
            <div className={styles.imageContainer}>
              {images.map((image, index) => (
                <GrommetImage
                  key={index}
                  src={loadedImages.includes(index) ? image : undefined}
                  alt={`Image ${index + 1}`}
                  fit="contain"
                  onLoad={handleImageLoad}
                  className={cx(styles.image, {
                    [styles.active]: index === activeIndex,
                    [styles.loaded]: index === activeIndex && imageLoaded,
                  })}
                />
              ))}
              <Box
                className={cx(styles.lotNumber, {
                  [styles.lotNumberSlider]: screenSlider,
                })}
                background="gray-200"
                margin={{ top: 'small', left: 'small' }}
                pad="small"
                round="small"
              >
                <Text size="xxlarge">
                  {`${formatMessage({ id: 'client.vehicle.lot_number' })} ${vehicle.lot}${
                    vehicle.lotComplement ? ` ${vehicle.lotComplement}` : ''
                  }`}
                </Text>
              </Box>
            </div>
            <Button
              className={cx(styles.buttons, styles.next)}
              onClick={handleNextImage}
              icon={<Next />}
              aria-label="Next"
            />
            {vehicle.salingState === 'Adjugé' && !vehicle.lotOpen && (
              <div
                data-cy="img-adjuge"
                className={cx(styles.sold, screenSlider && styles.fullWidth)}
                style={{
                  backgroundImage: `url(${require(`../../../assets/images/adjuge/adjuge-${
                    userLang as Languages
                  }.png`)})`,
                }}
              />
            )}
          </div>
        </Box>
      </Stack>
      {!screenSlider && (
        <Box>
          <Box background="gray-800" color="white" pad="xsmall" justify="center">
            <Text size="medium" alignSelf="center" weight="bold">
              <span dangerouslySetInnerHTML={{ __html: vehicle.maker }} />{' '}
              <span dangerouslySetInnerHTML={{ __html: vehicle.model }} />
            </Text>

            <Text size="medium" alignSelf="center" weight="bold">
              {`${vehicle.registrationDate} - ${vehicle.kilometers} ${formatMessage({
                id: 'client.vehicle.kilometers',
              })}`}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Carousel
