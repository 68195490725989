import { callAuthenticatedApi } from './utils'
import { QueryResult, useQuery, MutationResultPair, useMutation, queryCache } from 'react-query'
import { navigate } from '@reach/router'
import Timer, { getTimeLeft } from 'services/timer'

export interface CurrentSaleQueryResult {
  bidIncrement: number // The pitch for bids
  endDate: number // A of timestamp representing the end date in milliseconds
  id: string // The sale id
  lastOpenedVehicle: string | boolean // The id of the current vehicle
  liveVisitors: number // Number of visitor to the live
  name: string // The displayed name
  open: boolean // true if the sale is "in progress", false if it didn't start for know
  paused: boolean // Determine if the sale is paused for know (that's a thing the operator can do)
  recoveryDate: number
  room: string // The localization of the sale
  startDate: number // A of timestamp representing the start date in milliseconds
  openedVehicle: string | null
  saleId: string | null
  serverTimestamp: number | null
  server_timestamp: number | null
  updatedAt: number | null
  sold: boolean | null
  country: string
}

export const useCurrentSale = (): QueryResult<CurrentSaleQueryResult> => {
  return useQuery<CurrentSaleQueryResult, Error>(
    'currentSale',
    async () => {
      const { data } = await callAuthenticatedApi<CurrentSaleQueryResult>('live/api/sales/current')

      return data
    },
    {
      retry: false,
      onError: (e) => {
        if (e.message.includes('404')) {
          return (window.location.href = `${process.env.REACT_APP_API_URI}/portail-live/prochaines-ventes`)
        }
      },
    }
  )
}

export interface VehicleOrderSaleQueryResult {
  id: string
  order: number
  realOrder: number
}

export const useVehiclesOrderSale = (
  saleId?: string,
  options: object = {}
): QueryResult<VehicleOrderSaleQueryResult[]> => {
  return useQuery<VehicleOrderSaleQueryResult[], string>(
    'vehicleSale',
    async () => {
      const { data } = await callAuthenticatedApi<VehicleOrderSaleQueryResult[]>(
        `live/api/sales/${saleId}/vehicles`
      )

      return data
    },
    {
      ...options,
    }
  )
}

type HighestAction = {
  amount: string
  type: string
  currentUser: boolean
}

export interface VehicleSaleQueryResult {
  [x: string]: any // REMOVE THIS
  argusDatedPrice: string
  auctionDuration: number
  auctionOpen: boolean
  category: string
  ce: string
  client: string
  closedAt: number
  dateQuote: string
  defaultPitch: number
  energy: string
  estimationPrice: string
  etincelleId: number
  highestAuction: HighestAction
  hours: Array<unknown>
  id: string
  immat: string
  isClosedAllVehicles: boolean
  isSaleClosed: boolean
  isSalePaused: boolean
  justOpened: boolean
  kilometers: string
  listLink: string
  lot: number
  lotOpen: boolean
  maker: string
  model: string
  modelGroup: string
  nbj: string
  nbrLots: number
  note: string
  obsA: Array<string>
  openedAt: number
  options: Array<string>
  page_url: string
  pausedMs: number
  photo: string
  photos: Array<string>
  pricePitch: number
  r: string
  registrationDate: string
  reservePrice: number
  reservePriceHf: number
  salingState: string
  se: string
  bodyType: string
  segmentSize: string
  serverTimestamp: number
  server_timestamp: number
  slug: string
  startingDuration: number
  startingPrice: number
  startingPriceHf: number
  tarificationType: string
  tarification: string
  taxHorsePower: string
  unusedPrice: string
  vat: boolean
  vatAmount: number
  lastAuctions: AuctionQueryResult[]
}

export const useVehiclesSale = (
  saleId?: string,
  vehicleId?: string | null,
  options: object = {}
): QueryResult<VehicleSaleQueryResult> => {
  return useQuery<VehicleSaleQueryResult, Error>(
    'currentVehicle',
    async () => {
      const { data } = await callAuthenticatedApi<VehicleSaleQueryResult>(
        `live/api/sales/${saleId}/vehicles/${vehicleId}`
      )

      return data
    },
    {
      ...options,
      onSuccess(vehicle: VehicleSaleQueryResult) {
        // Start timer if auction is opened
        if (vehicle.auctionOpen) {
          Timer.getInstance().start(getTimeLeft(vehicle))
        }
      },
      onError(err) {
        if (err) {
          navigate('/no-sale')
        }
      },
    }
  )
}

export interface User {
  name: string
  email: string
  society: string
  type: string
}

export interface AuctionQueryResult {
  amount: number
  amountHf: number
  biggestPI: number
  createdAt: number
  id: string
  pausedMs: number
  paysVat: boolean
  type: string
  user: User
}

export const useAuctions = (
  saleId?: string,
  vehicleId?: string,
  options: object = {}
): QueryResult<AuctionQueryResult[]> => {
  return useQuery<AuctionQueryResult[], string>(
    ['auctions', vehicleId],
    async () => {
      const { data } = await callAuthenticatedApi<AuctionQueryResult[]>(
        `live/api/sales/${saleId}/vehicles/${vehicleId}/auctions`,
        {
          method: 'GET',
          params: {
            hash: new Date().getTime(),
            limit: 9,
          },
        }
      )
      return data
    },
    {
      ...options,
    }
  )
}

interface ChangeVehicleRequest {
  saleId: string
  vehicleId: string
}
export const useChangeVehicleSale = (): MutationResultPair<
  VehicleSaleQueryResult,
  Error,
  ChangeVehicleRequest,
  string
> => {
  return useMutation(
    async (payload: ChangeVehicleRequest) => {
      const { data } = await callAuthenticatedApi<VehicleSaleQueryResult>(
        `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`
      )

      return data
    },
    {
      onSuccess(vehicle: VehicleSaleQueryResult) {
        return queryCache.setQueryData('currentVehicle', vehicle)
      },
    }
  )
}

interface CloseSaleRequest {
  saleId: string
}

interface CloseSaleResponse {
  message: string
  status: string
}

export const useCloseSale = (): MutationResultPair<
  CloseSaleResponse,
  Error,
  CloseSaleRequest,
  string
> => {
  return useMutation(async (payload: CloseSaleRequest) => {
    const { data } = await callAuthenticatedApi<CloseSaleResponse>(
      `live/api/sales/${payload.saleId}/closed`,
      {
        method: 'PUT',
        params: {
          hash: new Date().getTime(),
        },
      }
    )

    return data
  })
}

interface OpenLotRequest {
  saleId: string
  vehicleId: string
}

interface OpenLotResponse {
  message: string
  status: string
}

export const useOpenLot = (): MutationResultPair<OpenLotResponse, any, OpenLotRequest, string> => {
  return useMutation(
    async (payload: OpenLotRequest) => {
      const { data } = await callAuthenticatedApi<OpenLotResponse>(
        `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
        {
          method: 'PUT',
          data: {
            lotOpen: 1,
            timeStamp: new Date().getTime(),
          },
        }
      )
      return data
    },
    {
      onError(err) {
        return err.message
      },
    }
  )
}

interface CloseLotRequest {
  saleId: string
  vehicleId: string
}

interface CloseLotResponse {
  message: string
  status: string
}

export const useCloseLot = (): MutationResultPair<
  CloseLotResponse,
  Error,
  CloseLotRequest,
  string
> => {
  return useMutation(async (payload: CloseLotRequest) => {
    const { data } = await callAuthenticatedApi<CloseLotResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: {
          lotOpen: 0,
          timeStamp: new Date().getTime(),
        },
      }
    )
    return data
  })
}

interface ChangePriceRequest {
  saleId: string
  vehicleId: string
  startPrice: string
}

interface ChangePriceResponse {
  message: string
  status: string
}

export const useChangePrice = (): MutationResultPair<
  ChangePriceResponse,
  Error,
  ChangePriceRequest,
  string
> => {
  return useMutation(async (payload: ChangePriceRequest) => {
    const { data } = await callAuthenticatedApi<ChangePriceResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: {
          startPrice: payload.startPrice,
          timeStamp: new Date().getTime(),
        },
      }
    )
    return data
  })
}

interface SallingStateRequest {
  saleId: string
  vehicleId: string
}

interface SallingStateResponse {
  message: string
  status: string
}

export const useSallingState = (): MutationResultPair<
  SallingStateResponse,
  Error,
  SallingStateRequest,
  string
> => {
  return useMutation(async (payload: SallingStateRequest) => {
    const { data } = await callAuthenticatedApi<SallingStateResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: {
          salingState: 'Adjugé',
          timeStamp: new Date().getTime(),
        },
      }
    )
    return data
  })
}

interface OpenAuctionRequest {
  saleId: string
  vehicleId: string
}

interface OpenAuctionResponse {
  message: string
  status: string
}

export const useOpenAuction = (): MutationResultPair<
  OpenAuctionResponse,
  Error,
  OpenAuctionRequest,
  string
> => {
  return useMutation(async (payload: OpenAuctionRequest) => {
    const { data } = await callAuthenticatedApi<OpenAuctionResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: {
          auctionOpen: 1,
          timeStamp: new Date().getTime(),
        },
      }
    )
    return data
  })
}

interface SendBidRequest {
  saleId: string
  vehicleId: string
  data: unknown
}

interface SendBidResponse {
  message: string
  status: string
}

export const useSendBid = (): MutationResultPair<
  SendBidResponse,
  Error,
  SendBidRequest,
  string
> => {
  return useMutation(async (payload: SendBidRequest) => {
    const { data } = await callAuthenticatedApi<SendBidResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: payload.data,
      }
    )
    return data
  })
}

interface CloseAuctionRequest {
  saleId: string
  vehicleId: string
}

interface CloseAuctionResponse {
  message: string
  status: string
}

export const useCloseAuction = (): MutationResultPair<
  CloseAuctionResponse,
  Error,
  CloseAuctionRequest,
  string
> => {
  return useMutation(async (payload: CloseAuctionRequest) => {
    const { data } = await callAuthenticatedApi<CloseAuctionResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: {
          deleteAuction: 1,
          timeStamp: new Date().getTime(),
        },
      }
    )

    return data
  })
}

interface PauseLotRequest {
  saleId: string
  hours: string
  minutes: string
}

interface PauseLotResponse {
  message: string
  status: string
}

export const usePauseLot = (): MutationResultPair<
  PauseLotResponse,
  Error,
  PauseLotRequest,
  string
> => {
  return useMutation(async (payload: PauseLotRequest) => {
    const { data } = await callAuthenticatedApi<PauseLotResponse>(
      `live/api/sales/${payload.saleId}/pause`,
      {
        method: 'PUT',
        data: {
          hours: payload.hours,
          minutes: payload.minutes,
          timestamp: new Date().getTime(),
        },
      }
    )
    return data
  })
}

interface RestoreLotRequest {
  saleId: string
}

interface RestoreLotResponse {
  message: string
  status: string
}

export const useRestoreLot = (): MutationResultPair<
  RestoreLotResponse,
  Error,
  RestoreLotRequest,
  string
> => {
  return useMutation(async (payload: RestoreLotRequest) => {
    const { data } = await callAuthenticatedApi<RestoreLotResponse>(
      `live/api/sales/${payload.saleId}/restore`,
      {
        method: 'PUT',
        params: {
          hash: new Date().getTime(),
        },
      }
    )
    return data
  })
}

interface ChangeDefaultPitchRequest {
  saleId: string
  vehicleId: string
  defaultPitch: number
}

interface ChangeDefaultPitchResponse {
  message: string
  status: string
}

export const useChangeDefaultPitch = (): MutationResultPair<
  ChangeDefaultPitchResponse,
  Error,
  ChangeDefaultPitchRequest,
  string
> => {
  return useMutation(async (payload: ChangeDefaultPitchRequest) => {
    const { data } = await callAuthenticatedApi<ChangeDefaultPitchResponse>(`live/api/setting`, {
      method: 'PUT',
      data: { name: 'bidStep', value: payload.defaultPitch },
    })
    return data
  })
}

interface ChangeBidPitchRequest {
  saleId: string
  vehicleId: string
  bidPitch: number
}

export const useChangeBidPitch = (): MutationResultPair<
  ChangeDefaultPitchResponse,
  Error,
  ChangeBidPitchRequest,
  string
> => {
  return useMutation(async (payload: ChangeBidPitchRequest) => {
    const { data } = await callAuthenticatedApi<ChangeDefaultPitchResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}`,
      {
        method: 'PUT',
        data: {
          pricePitch: payload.bidPitch,
        },
      }
    )
    return data
  })
}

interface SetStartingDurationRequest {
  saleId: string
  vehicleId: string
  second: number
}

interface SetStartingDurationResponse {
  message: string
  status: string
}

export const useSetStartingDuration = (): MutationResultPair<
  SetStartingDurationResponse,
  Error,
  SetStartingDurationRequest,
  string
> => {
  return useMutation(async (payload: SetStartingDurationRequest) => {
    const { data } = await callAuthenticatedApi<SetStartingDurationResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}/startingduration`,
      {
        method: 'PUT',
        data: {
          second: payload.second,
          timeStamp: new Date().getTime(),
        },
      }
    )

    return data
  })
}

interface SetAuctionDurationRequest {
  saleId: string
  vehicleId: string
  second: number
}

interface SetAuctionDurationResponse {
  message: string
  status: string
}

export const useSetAuctionDuration = (): MutationResultPair<
  SetAuctionDurationResponse,
  Error,
  SetAuctionDurationRequest,
  string
> => {
  return useMutation(async (payload: SetAuctionDurationRequest) => {
    const { data } = await callAuthenticatedApi<SetAuctionDurationResponse>(
      `live/api/sales/${payload.saleId}/vehicles/${payload.vehicleId}/auctionduration`,
      {
        method: 'PUT',
        data: {
          second: payload.second,
          timeStamp: new Date().getTime(),
        },
      }
    )

    return data
  })
}
