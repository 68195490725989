import FigureBox from 'components/FigureBox'
import { CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { Anchor, Box, Image, Text } from 'grommet'
import { Calendar, CircleInformation, Cursor, LinkPrevious } from 'grommet-icons'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './NextSale.module.scss'
import moment from 'moment'
import Link from './Link'
import logoLive from '../../assets/images/logo-live.png'
import cogTimer from '../../assets/images/cog-timer.png'
import logoVpauto from '../../assets/images/logo-vpauto.png'

interface OwnProps {
  currentSale: CurrentSaleQueryResult
}

type RemainingTime = {
  hours: number
  minutes: number
}

const NextSale: FC<OwnProps> = ({ currentSale }) => {
  const { formatMessage } = useIntl()
  const [remainingTime, setRemainingTime] = useState<RemainingTime>({ hours: 0, minutes: 0 })

  const formatters = {
    bold: function boldMaker(str: string) {
      return <span className={styles.bold}>{str}</span>
    },
  }

  const refreshInterval = useRef(
    setInterval(() => {
      const recoveryDate = moment(new Date(currentSale.recoveryDate * 1000))
      const currentDate = moment(new Date())

      const hoursVal = recoveryDate.diff(currentDate, 'hours')
      const minutesVal =
        recoveryDate.diff(currentDate, 'minutes') - recoveryDate.diff(currentDate, 'hours') * 60

      minutesVal > 0 || hoursVal > 0
        ? setRemainingTime({ hours: hoursVal, minutes: minutesVal })
        : setRemainingTime({ hours: 0, minutes: 0 })
    }, 1000)
  )

  useEffect(() => {
    return () => {
      clearInterval(refreshInterval.current)
    }
  }, [])

  return (
    <Box>
      <Box justify="between" direction="row">
        <Box width="small" pad="small" margin={{ left: 'medium' }}>
          <Anchor href="${process.env.REACT_APP_API_URI}">
            <Image fit="contain" src={logoVpauto}></Image>
          </Anchor>
        </Box>
        <Box
          className={styles.iconLiveBG}
          direction="row"
          alignContent="center"
          justify="start"
          gap="xlarge"
          pad={{ vertical: 'small' }}
        >
          <Box width="xsmall">
            <Image fit="contain" src={logoLive}></Image>
          </Box>
          <Text size="huge" alignSelf="center">
            {`${formatMessage({ id: 'client.header.live_from' })} ${currentSale.room}`}
          </Text>
        </Box>
      </Box>

      <Box alignContent="center" justify="around" direction="row" margin={{ top: 'xlarge' }}>
        <Box>
          <Image fit="contain" src={cogTimer}></Image>
        </Box>

        <Box data-cy="count-down">
          <Box justify="around" align="center">
            <Box margin={{ bottom: 'medium' }}>
              <Text alignSelf="center" className={styles.uppercase} size="huge">
                {formatMessage({ id: 'next_sale.content.paused' }, formatters)}
              </Text>
              <Text
                alignSelf="center"
                className={styles.uppercase}
                size="huge"
                margin="medium"
                color="blue-900"
              >
                {formatMessage({ id: 'next_sale.content.restored_in' }, formatters)}
              </Text>
            </Box>
            <Box direction="row" gap="small">
              <FigureBox
                figure={remainingTime.hours}
                label={formatMessage(
                  { id: 'next_sale.content.timer.hours' },
                  { hour: remainingTime.hours }
                )}
              ></FigureBox>
              <FigureBox
                figure={remainingTime.minutes}
                label={formatMessage(
                  { id: 'next_sale.content.timer.minutes' },
                  { minute: remainingTime.minutes }
                )}
              ></FigureBox>
            </Box>
          </Box>

          <Box pad="medium" margin={{ top: 'large' }} gap="medium">
            <Box className={styles.buttonAnchorCatalog}>
              <Anchor
                alignSelf="center"
                size="large"
                color="white"
                href={`${
                  process.env.REACT_APP_API_URI
                }/vehicules/liste/${currentSale.room.toLowerCase()}`}
                label={formatMessage({ id: 'next_sale.content.links.catalog' }, { ...formatters })}
              ></Anchor>
            </Box>
            <Box className={styles.buttonAnchorSaleHours}>
              <Anchor
                size="large"
                color="blue-900"
                alignSelf="center"
                href={`${process.env.REACT_APP_API_URI}/portail-live/horaires/${currentSale.id}`}
                label={formatMessage(
                  { id: 'next_sale.content.links.sale_hours' },
                  { ...formatters }
                )}
              ></Anchor>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        border={{ side: 'top', color: 'blue-900', size: 'large' }}
        direction="row"
        pad="medium"
        margin={{ top: 'xlarge' }}
        justify="around"
      >
        <Link
          href={process.env.REACT_APP_API_URI}
          label={formatMessage({ id: 'next_sale.footer.main_site' }, { ...formatters })}
          icon={<LinkPrevious color="blue-900" />}
        ></Link>

        <Link
          href="http://multimedia.vpauto.fr/documents/live/MODE_EMPLOI_DU_LIVE_V2.pdf"
          label={formatMessage({ id: 'next_sale.footer.guide' }, { ...formatters })}
          icon={<CircleInformation color="blue-900" />}
        ></Link>

        <Link
          href={`${process.env.REACT_APP_API_URI}/portail-live/prochaines-ventes`}
          label={formatMessage({ id: 'next_sale.footer.planning' }, { ...formatters })}
          icon={<Calendar color="blue-900" />}
        ></Link>

        <Link
          href={`${process.env.REACT_APP_API_URI}/portail-live/modes-d-acces`}
          label={formatMessage({ id: 'next_sale.footer.access' }, { ...formatters })}
          icon={<Cursor color="blue-900" />}
        ></Link>
      </Box>
    </Box>
  )
}

export default NextSale
