import { navigate, RouteComponentProps } from '@reach/router'
import Loading from 'app/Loading'
import { useApi } from 'contexts/ApiContext'
import React, { FC } from 'react'
import NextSale from './component'

const NextSaleContainer: FC<RouteComponentProps> = () => {
  const { sale } = useApi()

  const { data: currentSale } = sale.useCurrentSale()

  if (!currentSale) {
    return <Loading />
  }

  if (!currentSale.paused) {
    navigate('/client')
  }

  return <NextSale currentSale={currentSale} />
}
export default NextSaleContainer
