import React, { FC, useContext } from 'react'
import { Box, Text, ResponsiveContext } from 'grommet'
import { Currency } from 'components/Number'
import Triangle from 'components/Triangle'
import { useIntl } from 'react-intl'

export type Origin = 'you' | 'salle' | 'web' | 'interenchères' | 'live' | 'oa'

const Bid: FC<{
  value: number
  level?: number
  origin?: Origin
  width?: string
  textSize?: string
}> = ({ value, origin, level = 0, width = '100%', textSize = '2rem', ...props }) => {
  const { formatMessage } = useIntl()
  const size = useContext(ResponsiveContext)
  const isMobile = ['xsmall', 'small'].includes(size)
  const isCurrent = level === 0
  if (isMobile) {
    width = '90%'
  }
  const grayLevel = (6 - (Math.min(level, 3) - 1)) * 100
  return (
    <Box width={width} justify="center" align="center" direction="row" margin="xsmall" {...props}>
      <Triangle color={isCurrent ? 'lime-500' : 'transparent'} />
      <Box
        flex="grow"
        direction="row"
        align="center"
        justify="center"
        background={isCurrent ? 'lime-500' : `gray-${grayLevel}`}
        round="small"
        pad="xsmall"
      >
        <Text size={textSize} color="white">
          {origin && formatMessage({ id: `client.auction.${origin}` })}&nbsp;:&nbsp;
        </Text>
        <Currency color="white" value={value} textAlign="center" size={textSize} weight="bold" />
      </Box>
    </Box>
  )
}

export default Bid
