import TinyTimer from 'tiny-timer'
import { queryCache } from 'react-query'
import { VehicleSaleQueryResult } from 'contexts/ApiContext/services/sale'

export enum Status {
  RUNNING = 'running',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

export const getTimeLeft = (vehicle: VehicleSaleQueryResult): number => {
  const decals = new Date().getTime() - vehicle.serverTimestamp
  const mllsec = vehicle.closedAt + vehicle.pausedMs - (new Date().getTime() - decals)
  return mllsec
}

class Timer {
  private static _instance: TinyTimer

  public static getInstance(): TinyTimer {
    if (Timer._instance == null) {
      Timer._instance = new TinyTimer()
      this.onLoad()
    }
    return this._instance
  }
  constructor() {
    if (Timer._instance) {
      throw new Error('Error: Instantiation failed: Use Timer.getInstance() instead of new.')
    }
  }

  private static onLoad(): void {
    Timer._instance.on('tick', (ms: number) => {
      if (Math.round(ms / 1000) === 0) {
        this.closeAuction()
      }
    })
  }

  private static closeAuction(): void {
    const currentVehicle = queryCache.getQueryData('currentVehicle') as VehicleSaleQueryResult

    queryCache.setQueryData('currentVehicle', { ...currentVehicle, auctionOpen: false })
  }
}

export default Timer
