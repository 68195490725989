import React, { FC, ReactNode } from 'react'

const LoaderContext = React.createContext(false)

interface OwnProps {
  loading: boolean
  children: ReactNode
}

export const LoaderProvider: FC<OwnProps> = (props: OwnProps) => {
  return <LoaderContext.Provider value={props.loading}>{props.children}</LoaderContext.Provider>
}

export const useLoader = (): boolean => {
  const isLoading = React.useContext(LoaderContext)

  return isLoading
}
