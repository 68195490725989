import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useIntl } from 'react-intl'
import { Heading, Box } from 'grommet'

const Loading: FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()
  return (
    <div>
      <Heading textAlign="center">{formatMessage({ id: 'home.title' })}</Heading>
      <Box direction="row" gap="large" wrap justify="center">
        {formatMessage({ id: 'loading.app' })}
      </Box>
    </div>
  )
}

export default Loading
