import React, { FC } from 'react'
import { Box, BoxProps, Anchor } from 'grommet'

import styles from './Link.module.scss'

interface LinkProps extends BoxProps {
  label: React.ReactNode
  href: string | undefined
  icon: React.ReactNode
}

const Link: FC<LinkProps> = ({ label, href, icon, ...props }) => {
  return (
    <Box direction="row" align="center" gap="small">
      {icon}
      <Anchor
        color="blue-900"
        target="_blank"
        className={styles.uppercase}
        size="large"
        href={href}
        label={label}
      ></Anchor>
    </Box>
  )
}

export default Link
