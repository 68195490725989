import colors from './colors'

export default {
  global: {
    breakpoints: {
      small: {
        value: 1000,
      },
      medium: {
        value: 1200,
      },
    },
    colors,
    focus: {
      border: {
        color: 'blue-300',
      },
    },
  },
  button: {
    border: {
      radius: '0.25rem',
    },
    size: {
      small: {
        border: {
          radius: '0.25rem',
        },
      },
    },
  },
  text: {
    xsmall: {
      size: `0.6rem`,
      height: '1.3',
    },
    small: {
      size: `0.8rem`,
      height: '1.3',
    },
    medium: {
      size: `1rem`,
      height: '1.3',
    },
    large: {
      size: `1.2rem`,
      height: '1.3',
    },
    xlarge: {
      size: `1.4rem`,
      height: '1.3',
    },
    xxlarge: {
      size: `1.6rem`,
      height: '1.3',
    },
    huge: {
      size: `2rem`,
      height: '1.3',
    },
    giant: {
      size: `3rem`,
      height: '1.3',
    },
    xgiant: {
      size: `4rem`,
      height: '1.3',
    },
  },
  anchor: {
    fontWeight: 400,
    hover: {
      textDecoration: 'none',
    },
  },
}
