import { navigate, RouteComponentProps } from '@reach/router'
import Loading from 'app/Loading'
import { useApi } from 'contexts/ApiContext'
import useLastOpenedVehicle from 'hooks/useLastOpenedVehicule'
import React, { FC } from 'react'
import Customer from './component'

const CustomerContainer: FC<RouteComponentProps> = () => {
  const { sale } = useApi()

  const { data: currentSale } = sale.useCurrentSale()

  const lastOpenedVehicle = useLastOpenedVehicle(currentSale)

  const { data: vehicle } = sale.useVehiclesSale(
    currentSale?.id,
    currentSale?.openedVehicle || lastOpenedVehicle,
    {
      enabled: currentSale,
    }
  )

  if (currentSale?.paused) {
    navigate('/next-sale')
  }

  const { data: auctions } = sale.useAuctions(currentSale?.id, vehicle?.id, {
    enabled: vehicle,
  })

  if (!currentSale || !vehicle || !auctions) {
    return <Loading />
  }

  if (!currentSale.open) {
    window.location.href = `${process.env.REACT_APP_API_URI}/portail-live/prochaines-ventes`
  }

  return <Customer currentSale={currentSale} vehicle={vehicle} auctions={auctions} />
}
export default CustomerContainer
