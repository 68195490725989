import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useApi } from 'contexts/ApiContext'
import Loading from 'app/Loading'
import withRoles, { ROLES } from 'hoc/withRoles'
import Header from './component'
import { queryCache } from 'react-query'
import { UsersLoggedListQueryResult } from 'contexts/ApiContext/services/users'
import { LoginSocketResponse } from 'contexts/WebSocketContext/types'

const SalespersonContainer: FC<RouteComponentProps> = () => {
  const { sale } = useApi()
  const { data: currentSale } = sale.useCurrentSale()
  const usersList = queryCache.getQueryData('usersLoggedList') as UsersLoggedListQueryResult
  const { count } = queryCache.getQueryData('usersConnected') as LoginSocketResponse

  if (!currentSale) {
    return <Loading />
  }

  return <Header sale={currentSale} usersList={usersList} connected={count} />
}
export default withRoles<RouteComponentProps>({
  roles: [ROLES.isSalesperson],
})(SalespersonContainer)
