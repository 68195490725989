import React, { FC } from 'react'
import { Logout, User } from 'grommet-icons'
import { Link } from '@reach/router'
import { Anchor, Box, Text } from 'grommet'
import { useIntl } from 'react-intl'
import { useAuth } from 'contexts/AuthContext'

const UserName: FC = () => {
  const { formatMessage } = useIntl()
  const { user } = useAuth()
  return (
    <>
      <Box background="white" direction="row" margin="xsmall" align="center">
        <User size="medium" color="black" />
        <Text weight="bold" size="small" margin={{ left: 'xsmall' }}>
          {user.name}
        </Text>
        {!user.isDemoUser && (
          <Link to="/logout">
            <Box direction="row" align="center" margin={{ left: 'medium' }}>
              <Logout size="small" color="red-500" />
              <Anchor
                size="small"
                color="red-500"
                margin={{ left: 'xsmall' }}
                label={formatMessage({ id: 'client.header.logout' })}
              ></Anchor>
            </Box>
          </Link>
        )}
      </Box>
    </>
  )
}

export default UserName
