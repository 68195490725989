import React, { FC } from 'react'
import { Box, BoxProps, Text } from 'grommet'
import {
  VehicleSaleQueryResult,
  AuctionQueryResult,
  CurrentSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import Waiting from './Waiting'
import Biding from './Biding'
import { useState, useEffect } from 'react'
import Timer from 'services/timer'
import TimerC from '../../components/Timer'
import { useIntl } from 'react-intl'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
  currentSale: CurrentSaleQueryResult
}

const Auctions: FC<OwnProps & BoxProps> = ({ vehicle, auctions, currentSale, ...props }) => {
  const { formatMessage } = useIntl()

  const [tick, setTick] = useState<number | null>(null)

  useEffect(() => {
    Timer.getInstance().on('tick', (ms: number) => {
      const timeLeft = Math.ceil(ms / 1000)
      setTick(timeLeft < 0 ? null : timeLeft)
    })
  }, [vehicle])

  return (
    <Box direction="column" justify="between" height="100%" {...props}>
      <Box background="blue-800" pad="small" direction="row" justify="around" align="center">
        {vehicle.auctionOpen && <TimerC tick={tick} textNumberSize="70px" showSeconds={false} />}
        <Text size="80px" textAlign="center" weight="bold">
          {formatMessage({ id: 'hall.title' })}
        </Text>
      </Box>
      {(vehicle.auctionOpen || auctions.length > 0) && (
        <>
          <Biding vehicle={vehicle} currentSale={currentSale} auctions={auctions} />
        </>
      )}
      {((!vehicle.auctionOpen && auctions.length === 0) || currentSale.paused) && (
        <Waiting vehicle={vehicle} data-cy="waiting-room-screen" />
      )}
      <Box direction="row" justify="between" align="center" pad="small">
        <Box>
          <Text size="45px" data-cy="price-room-screen">
            {formatMessage({ id: 'hall.newPrice' }, { price: vehicle?.unusedPrice + ' €' })}
          </Text>
        </Box>
        <Box direction="row" align="center" gap="small" data-cy="tva-room-screen">
          <Text size="45px" weight="bold">
            {formatMessage({ id: 'hall.taxes.label' })}
          </Text>
          <Box border={{ color: 'black' }} pad="xsmall" round="xsmall">
            <Text size="45px" weight="bold">
              {vehicle?.vat
                ? formatMessage({ id: 'hall.taxes.yes' })
                : formatMessage({ id: 'hall.taxes.no' })}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Auctions
