import { default as de } from './de'
import { default as en } from './en'
import { default as es } from './es'
import { default as fr } from './fr'
import { default as hu } from './hu'
import { default as it } from './it'
import { default as pl } from './pl'
import { default as nl } from './nl'
import { default as pt } from './pt'
import { default as ro } from './ro'

export type Languages = 'fr' | 'de' | 'en' | 'es' | 'hu' | 'it' | 'pl' | 'pt' | 'ro' | 'nl'

export default {
  fr,
  de,
  en,
  es,
  hu,
  it,
  pl,
  pt,
  ro,
  nl,
}
