import React, { FC } from 'react'
import { TextProps } from 'grommet'
import Number from './Number'

interface CurrencyProps extends TextProps {
  value: number
  currency?: string
  className?: string
}

const Currency: FC<CurrencyProps> = ({ value, currency = 'EUR', ...props }) => {
  return (
    <Number
      value={value}
      options={{
        minimumFractionDigits: 0,
        style: 'currency',
        currency,
      }}
      {...props}
    />
  )
}

export default Currency
