export default {
  'home.title': 'VPAuto live',
  'home.menu.auctioneer': 'Commissaire priseur',
  'home.menu.customer': 'Client',
  'home.menu.hall': 'Salle des ventes',
  'home.menu.operator': 'Opérateur',
  'home.menu.salesperson': 'Commercial',
  'home.menu.sliderimages': 'Slider images',
  'operator.dashboard.openLot': 'Ouvrir le lot',
  'operator.dashboard.openAuctions': 'Ouvrir les enchères',
  'operator.dashboard.pause': 'Mettre en pause',
  'operator.dashboard.restart': 'Restaurer la vente',
  'operator.dashboard.close': 'Clôturer le lot',
  'operator.dashboard.end': 'Terminé',
  'operator.dashboard.ok': 'Ok',
  'operator.dashboard.defaultStep': 'Pas par défaut',
  'operator.dashboard.editPrice': 'Modifier le prix de départ',
  'operator.dashboard.interauctionBid': 'Enchérir Interenchères',
  'operator.dashboard.startTimer': 'Chrono de départ',
  'operator.dashboard.auctionTimer': 'Chrono des enchères',
  'operator.dashboard.hallBid': 'Enchérir salle',
  'operator.auctions.origin': 'Origine',
  'operator.auctions.buyer': "Nom de l'acheteur",
  'operator.auctions.company': 'Société',
  'operator.auctions.user.country': 'Pays',
  'operator.auctions.user.type': 'Type',
  'operator.auctions.user.type.user': 'Particulier',
  'operator.auctions.user.type.society': 'Société',
  'operator.auctions.user.type.pro': 'PRO',
  'operator.auctions.amountHF': 'Montant HF',
  'operator.auctions.amount': 'Montant',
  'operator.auctions.maxBid': 'Offre max',
  'operator.autions.editPrice.modal.title': 'Modifier le prix de départ',
  'operator.autions.editPrice.modal.label': 'Prix de départ',
  'auctioneer.dashboard.status.open': 'En cours',
  'auctioneer.dashboard.status.close': 'Terminé',
  'auctioneer.dashboard.reservePrice': 'Prix de réserve',
  'auctioneer.dashboard.nextAuction': 'Prochaine enchère',
  'auctioneer.dashboard.nextAuction.step': '(Pas de {step} €)',
  'auctioneer.dashboard.reduceReservePrice': "Baisser le prix de réserve à l'enchère en cours",
  'auctioneer.dashboard.award': 'Adjuger',
  'lot.toolbox.label': 'Lot N° {number}',
  'lot.toolbox.next.a11yLabel': 'Lot suivant',
  'lot.toolbox.shutdown.a11yLabel': 'Clôturer la vente',
  'lot.toolbox.previous.a11yLabel': 'Lot précédent',
  'lot.toolbox.first.a11yLabel': 'Aller au premier lot',
  'lot.toolbox.last.a11yLabel': 'Aller au dernier lot',
  'lot.toolbox.modal.changeLot.title': 'Choisissez un lot',
  'lot.toolbox.modal.changeLot.validate': 'Valider',
  'lot.toolbox.modal.changeLot.close': 'Fermer',
  'lot.toolbox.modal.changeLot.error': 'Aucun lot ne correspond à votre recherche',
  'lot.toolbox.modal.closeSale.title': 'Êtes-vous sûr de vouloir clôturer cette vente ?',
  'lot.toolbox.modal.closeSale.yes': 'Oui',
  'lot.toolbox.modal.closeSale.no': 'Non',
  'lot.toolbox.modal.closeAuction.title': 'Supprimer la dernière enchère ?',
  'lot.toolbox.modal.closeAuction.yes': 'Oui',
  'lot.toolbox.modal.closeAuction.no': 'Non',
  'lot.toolbox.modal.closeLot.title':
    'Le montant de la meilleure enchère est inférieur à celui de la réserve.',
  'lot.toolbox.modal.closeLot.subtitle': 'Souhaitez-vous adjuger ?',
  'lot.toolbox.modal.closeLot.yes': 'Oui',
  'lot.toolbox.modal.closeLot.no': 'Non',
  'lot.toolbox.modal.pauseLot.choseTime': 'Choisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.grabTime': 'Saisissez une heure de reprise',
  'lot.toolbox.modal.pauseLot.validate': 'Valider',
  'salesperson.header.userCounter.spectator.label':
    '{value, plural, zero {spectateur} one {spectateur} other {spectateurs}}',
  'salesperson.header.userCounter.spectator.title':
    'Total des clics sur le bouton du mode spectateur.',
  'salesperson.header.userCounter.identified.label':
    '{value, plural, zero {identifié} one {identifié} other {identifiés}}',
  'salesperson.header.userCounter.identified.title': 'Total des identifiés de la journée.',
  'salesperson.header.userCounter.loggedIn.label':
    '{value, plural, zero {connecté} one {connecté} other {connectés}}',
  'salesperson.header.userCounter.loggedIn.title':
    'Anonymes et identifiés actuellement sur le Live.',
  'salesperson.header.title': 'Vente live de {name}',
  'salesperson.dataTable.company.yes': 'Oui ({name})',
  'salesperson.dataTable.company.no': 'Non',
  'salesperson.dataTable.column.name': 'Nom',
  'salesperson.dataTable.column.email': 'Email',
  'salesperson.dataTable.column.country': 'Pays',
  'salesperson.dataTable.column.lastConnectedAt': 'Connexion',
  'salesperson.dataTable.column.isPro': 'Pro',
  'salesperson.dataTable.column.auctions': 'Enchères',
  'salesperson.header.logout.label': 'Se déconnecter',
  'hall.title': 'VENTA EN VIVO',
  'hall.lotNumber.label': 'N.º{number}',
  'hall.newPrice': 'Precio nuevo* : {price}',
  'hall.taxes.label': 'IVA',
  'hall.taxes.yes': 'SÍ',
  'hall.taxes.no': 'NO',
  'client.attr.auction.loading': 'Cargando...',
  'client.attr.vehicle.photo_next': 'Imagen siguiente',
  'client.attr.vehicle.photo_previous': 'Imagen anterior',
  'client.auction.bid': 'Pujar',
  'client.auction.fees_extra': 'Gastos de expediente aparte por valor de {handlingFees} €',
  'client.auction.fees_included': 'Gastos de venta incluidos',
  'client.auction.few_moments': 'en unos instantes',
  'client.auction.losing': 'Su puja no es la más alta',
  'client.auction.lost_text': 'No ha obtenido la subasta.',
  'client.auction.lot_closed': 'Lote finalizado',
  'client.auction.next_auction': 'Próxima puja',
  'client.auction.opening_in': 'Apertura de las subastas',
  'client.auction.register_to_bid': 'Inscríbase para pujar',
  'client.auction.same_amount': 'Misma puja para este importe',
  'client.auction.seconds': 'Segundo(s)',
  'client.auction.sold': 'Adjudicado',
  'client.auction.starting_price': 'Precio inicial',
  'client.auction.vat_excluded': 'VAT Excluido',
  'client.auction.winning': 'Su puja es la más alta',
  'client.auction.won_text1': '¡Enhorabuena! ha ganado la subasta',
  'client.auction.won_text2': 'Le contactaremos.',
  'client.auction.you': 'Usted',
  'client.auction.salle': 'Sala',
  'client.auction.interenchères': 'Web',
  'client.auction.web': 'Web',
  'client.auction.live': 'Web',
  'client.auction.oa': 'Web',
  'client.footer.catalog': 'Volver al catálogo',
  'client.footer.footnote.one': 'Gastos de expediente aparte por valor de {handlingFees} €',
  'client.footer.footnote.three': 'Referencia Codex con la fecha de salida o factura original',
  'client.footer.footnote.two':
    'Cotización prorata temporis, o precio de mercado, o cotización estimada, dados a título indicativo, no comprometiendo la responsabilidad de VPauto',
  'client.footer.vehicle_link': 'Ficha detallada del lote',
  'client.header.live_from': 'En directo desde',
  'client.header.logout': 'Desconexión',
  'client.popup.body.first': 'Usted entra en la sala en modo pujador.',
  'client.popup.body.second':
    'Si hace clic en el botón «Pujar» estará pujando y se estará comprometiendo.',
  'client.popup.body.third':
    'Las pujas realizadas incluyen los gastos de venta, pero hay que añadir aparte los gastos de expediente.',
  'client.popup.confirm': 'Entendido',
  'client.popup.header': 'Atención',
  'client.vehicle.kilometers': 'Km',
  'client.vehicle.lot_number': 'N.º',
  'client.vehicle.new_price': 'Precio nuevo',
  'client.vehicle.observations': 'Observaciones',
  'client.vehicle.options': 'Equipamientos',
  'client.vehicle.quote': 'Cotización',
  'client.vehicle.vat': 'IVA',
  'client.vehicle.localisation': 'Ubicación',
  'client.video.live_sale': 'Ver la venta en vivo',
  'loading.app': 'Por favor espere, conexión en curso...',
  'loading.sale': 'Carga de la venta en curso...',
  'loading.vehicle': 'Carga del vehículo en curso...',
  'next_sale.content.links.catalog': 'Catálogo de venta',
  'next_sale.content.links.sale_hours': 'Horarios de la venta',
  'next_sale.content.paused': '<bold>Venta</bold> pausada',
  'next_sale.content.restored_in': '<bold>Volvemos a comenzar</bold> en',
  'next_sale.content.timer.hours': `{hour, plural,
    =0 {hora}
    =1 {hora}
    other {horas}
  }`,
  'next_sale.content.timer.minutes': `{minute, plural,
    =0 {minuto}
    =1 {minuto}
    other {minutos}
  }`,
  'next_sale.footer.access': 'Elegir su <bold>acceso</bold>',
  'next_sale.footer.guide': '<bold>Modo de empleo</bold> del Live',
  'next_sale.footer.main_site': '<bold>Volver</bold> a VPauto',
  'next_sale.footer.planning': '<bold>Programación</bold> de las próximas subastas Live',
  'next_sale.footer.sound': '<bold>Escuchar</bold> la venta',
  'next_sale.footer.sound_instructions': 'Esta funcionalidad no está disponible actualmente.',
  'next_sale.header.live_from': '@:client.header.live_from',
}
