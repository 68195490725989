import React, { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { Box, DataTable, Text, Button, TextInput } from 'grommet'
import { FormSearch, Update } from 'grommet-icons'
import Header from './Header'
import { useIntl } from 'react-intl'
import { User } from 'contexts/ApiContext/services/users'

interface OwnProps {
  users: User[]
  refresh: any
}

const Salesperson: FC<OwnProps> = ({ users, refresh }) => {
  const { formatMessage } = useIntl()
  const [search, setNewSearch] = useState<string>('')
  const [usersList, setUsersList] = useState<User[]>(users)

  useEffect(() => {
    setUsersList(users)
  }, [users])

  useEffect(() => {
    const newUsersList = users.filter(
      (user) => user.email.includes(search) || user.name.includes(search)
    )
    setUsersList(newUsersList)
  }, [search])

  return (
    <Box>
      <Header />
      <Box direction="row" justify="end">
        <Box background="gray-300" pad="xsmall" direction="row" align="center">
          <TextInput
            dropHeight="xsmall"
            placeholder=""
            value={search}
            onChange={(event: any) => {
              setNewSearch(event.target.value)
            }}
          />
          <FormSearch />
        </Box>
        <Box background="gray-300" pad="xsmall" direction="row" align="center">
          <Button
            primary
            color="gray-300"
            label={<Update size="small" />}
            onClick={() => refresh()}
          />
        </Box>
      </Box>
      <Box fill="horizontal" overflow="auto">
        <DataTable
          data-cy="saler-table"
          sortable
          background={{
            header: 'gray-300',
          }}
          columns={[
            {
              property: 'name',
              header: <Text>{formatMessage({ id: 'salesperson.dataTable.column.name' })}</Text>,
              primary: true,
              render: function Name(datum) {
                return <Text color={datum.logged ? 'black' : 'gray-500'}>{datum.name}</Text>
              },
            },
            {
              property: 'email',
              header: <Text>{formatMessage({ id: 'salesperson.dataTable.column.email' })}</Text>,
              render: function Email(datum) {
                return <Text color={datum.logged ? 'black' : 'gray-500'}>{datum.email}</Text>
              },
            },
            {
              property: 'country',
              header: <Text>{formatMessage({ id: 'salesperson.dataTable.column.country' })}</Text>,
              render: function Country(datum) {
                return <Text color={datum.logged ? 'black' : 'gray-500'}>{datum.country}</Text>
              },
            },
            {
              property: 'loginAt',
              header: (
                <Text>{formatMessage({ id: 'salesperson.dataTable.column.lastConnectedAt' })}</Text>
              ),
              render: function LastConnectedAt(datum) {
                moment.locale(navigator.language)
                const date = moment(datum.loginAt).locale('fr')
                return (
                  <Text color={datum.logged ? 'black' : 'gray-500'}>{date.format('HH:mm:ss')}</Text>
                )
              },
            },
            {
              property: 'isPro',
              header: <Text>{formatMessage({ id: 'salesperson.dataTable.column.isPro' })}</Text>,
              render: function Company(datum) {
                return <Text color={datum.logged ? 'black' : 'gray-500'}>{datum.isPro}</Text>
              },
            },
            {
              property: 'nbBids',
              header: <Text>{formatMessage({ id: 'salesperson.dataTable.column.auctions' })}</Text>,
              render: function Auctions(datum) {
                return (
                  <Button
                    primary
                    color={datum.logged ? 'blue-600' : 'gray-500'}
                    label={`${datum.nbBids}`}
                  />
                )
              },
            },
          ]}
          data={usersList}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Salesperson
